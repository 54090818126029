/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Carousel {
	position: relative;
	// height: calc(100% - 10.25em);
	height: 100%;
	width: 100%;
	touch-action: none;
	overflow: hidden;
	&--avatars {
		height: 100%;
		.Carousel-cardWrap {
			top: 0;
			left: 3.125em;
			left: calc(50% - 8.125em);
			width: 16.25em;
		}
		.Carousel-card {
			width: 14em;
			height: 28em;
			padding: 1em 0.5em;
			margin: 0;
		}
	}

	&--interests {
		.Carousel-card {
			height: 24.313em;
		}
	}
}

.Carousel-cardWrap {
	position: absolute;
	left: 2.625em;
	left: calc(50% - 8.625em);
	width: 17.25em;
	height: 100%;
	padding-left: 1.125em;
	will-change: transform;
	@include flex('center','center');
}

.Carousel-card {
	position: relative;
	text-align: left;
	width: 15em;
	height: 27.5em;
	padding: 0.5em 1.25em;
	border-radius: $border-radius;
	color: $text-dark;
	background-color: #F0F0F0;
	will-change: transform;
	@include box-shadow-custom(0, 0.1875em, 0.375em, 0, rgba(0, 0, 0, 0.16));
}

// @media screen and (max-height: 668px) {
// 	.Carousel-card {
// 		height: 26.2em;
// 	}
	
// }