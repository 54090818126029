/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.QuizOverview {
	margin-top: 2.4em;

	.Button {
		margin-top: 2.4em;
	}

	.QuizOverview-category {
		position: relative;
		width: 18.875em;
		height: 3.625em;
		margin: 0 auto;
		border-top: solid $text-dark 0.025em;
		text-align: left;
		&:nth-of-type(5) {
			border-bottom: solid 0.025em;
		}
	}

	.QuizOverview-icon {
		position: absolute;
		left: 1.5em;
		top: 0.531em;
		width: 2.4em;
		height: 2.4em;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;

		&--danish {
			background-image: url('../../assets/images/stats/danish.svg');
			&.green {background-image: url('../../assets/images/stats/danish-green.svg');}
			&.red {background-image: url('../../assets/images/stats/danish-red.svg');}
		}
		&--reading {
			background-image: url('../../assets/images/stats/reading.svg');
			&.green {background-image: url('../../assets/images/stats/reading-green.svg');}
			&.red {background-image: url('../../assets/images/stats/reading-red.svg');}
		}
		&--math {
			background-image: url('../../assets/images/stats/math.svg');
			&.green {background-image: url('../../assets/images/stats/math-green.svg');}
			&.red {background-image: url('../../assets/images/stats/math-red.svg');}
		}
		&--teamwork {
			background-image: url('../../assets/images/stats/teamwork.svg');
			&.green {background-image: url('../../assets/images/stats/teamwork-green.svg');}
			&.red {background-image: url('../../assets/images/stats/teamwork-red.svg');}
			width: 2.6em;
			height: 2.4em;
		}
		&--workEthic {
			background-image: url('../../assets/images/stats/workEthic.svg');
			&.green {background-image: url('../../assets/images/stats/workEthic-green.svg');}
			&.red {background-image: url('../../assets/images/stats/workEthic-red.svg');}
		}
	
	}

	.QuizOverview-name {
		display: table-cell;
		vertical-align: middle;
		width: 9em;
		height: 3em;
		padding-left: 4.6em;
		font-size: 1.188em;
		color: $text-dark;
	}

	.QuizOverview-stat {
		position: absolute;
		right: 1.7em;
		top: 0.8em;
		font-size: 1.250em;
		font-weight: 700;
		color: $text-dark;
	}

	.QuizOverview-checkmark {
		position: absolute;
		right: 0.3em;
		top: 1.1em;
		width: 1.2em;
		height: 1.4em;
		background-image: url('../../assets/images/icon-check-mark.svg');
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
		&.animate {
			opacity: 0;
			-webkit-animation: fadeIn 0.5s linear;
			animation: fadeIn 0.5s linear;
			-webkit-animation-delay: 0.5s;
			animation-delay: 0.5s;
			animation-fill-mode: forwards;
			-webkit-animation-fill-mode: forwards;
	
		}
	}
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
@keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}



.QuizOverview.QuizOverview--landscape {
	margin-top: 0;
	.QuizOverview-card {
		width: vw-calc(795);
		height: vw-calc(630);
		margin: 0 auto;
		background-color: white;
		padding: vw-calc(20) vw-calc(90);		
	}
	.Button {
		margin-top: 0;
	}

	.QuizOverview-category {
		width: 100%;
		height: vw-calc(115);
		border-top: solid $text-dark vw-calc(1);
		&:nth-of-type(1) {border: none;}
		&:nth-of-type(5) {
			border: none;
			border-top: solid $text-dark vw-calc(1);
		}
	}

	.QuizOverview-icon {
		position: absolute;
		left: 0;
		top: 0;
		width: vw-calc(120);
		height: vw-calc(115);
		background-size: auto vw-calc(80);
		background-position: center;
	}



	.QuizOverview-name {
		font-size: vw-calc(30);
		line-height: 3.8;
		width: 9em;
		height: 3.8em;
		padding-left: 5em;
	}

	.QuizOverview-stat {
		font-size: vw-calc(35);
		right: 2em;
		line-height: 3.3;
		top: 0;
		font-weight: 700;
	}

	.QuizOverview-checkmark {
		position: absolute;
		right: 0;
		top: 0;
		width: vw-calc(60);
		height: vw-calc(115);
		background-size: auto vw-calc(45);
		background-position: center;
	}


}
