/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Profile.Profile--landscape {
	padding: vw-calc(70) 0 0 0;
	background-image: url('../../assets/images/background-landscape.jpg');
	background-size: cover;
	input[type="number"] {
		-webkit-appearance: textfield;
			 -moz-appearance: textfield;
						appearance: textfield;
	}
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none;
	}

	.AvatarImage {
		width: vw-calc(234);
		height: vw-calc(234);
	}

	.Profile-form {
		margin: vw-calc(-90) auto vw-calc(60) auto;
		width: vw-calc(800);
		height: vw-calc(630);
		background-color: white;
		padding: vw-calc(140) vw-calc(115) vw-calc(40) vw-calc(115);
		@include input-placeholder(white);
		@include box-shadow-custom(0, vw-calc(3), vw-calc(3), 0, rgba(black, 0.16));
		Input {
			font-size: vw-calc(25);
			height: 3em;
			border-radius: 0.3em;
			background-color: #A4D0F1;
			border: vw-calc(1) solid #BCE0FD;
			color: white;
			vertical-align: top;
			&[name="name"] {
				width: 14.4em;
				margin-left: 0;
				padding-left: 1em;
			}
			&[name="age"] {
				width: 5.6em;
				margin-left: 1em;
			}
		}

		.Profile-formEl {
			margin-top: 0;
			text-align: center;
			&--experience {
				margin-top: 0;
				margin-bottom: vw-calc(30);
				@include flex(space-between, flex-start, row);
				.Profile-label {
					margin-top: vw-calc(70);
				}
			}
		}

		.Profile-label {
			display: block;
			font-size: vw-calc(25);
			height: 1.2em;
			line-height: 1;
			font-weight: bold;
			margin-bottom: 0.5em;
			margin-left: 0;
			padding-left: 1.5em;
		}		

		.Select.education {
			font-size: 1em;
			width: vw-calc(525);
			.Select-selected {
				padding-top:0;
			}
		}
	
		.Select.experience {
			font-size: 1em;
			width: vw-calc(140);
			margin-right: vw-calc(25);
			margin-left: 0;
			.Select-selected {
				padding-left: vw-calc(20);
			}
		}
	}

	.Profile-feedback {
		font-size: vw-calc(20);
		height: 3em;
		color: $red;
	}
}