/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/variables';
.App {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  color: black;
  background-color: $grey2;
  background-image: linear-gradient(#F2F8FC, #C5DFF3);
  text-align: center;
  &--portrait {
    // min-height: calc(1.7778 * 100vw);
    min-height: 100%;
    .App-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 100%;

    }
  }
  &--landscape {
    background-color: #D5ECFE;
    background-image: none;
    .App-wrap {
      position: relative;
      width: 100%;
      height: calc(100vw / (16/9));
      font-size: calc(100vw / 22.5);   
      margin: auto;
      overflow: hidden;   
    }    
  }
  &--admin {
    background: white;
  }
}




@media (min-aspect-ratio: 16/9) {
	.App.App--landscape .App-wrap {
    width: calc(((16/9) * 100) * 1vh);
    height: 100vh;
    font-size: calc((((16/9) * 100) * 1vh) / 22.5);
    border-left: 1px solid $grey2;
    border-right: 1px solid $grey2;
	}
}
@media (max-aspect-ratio: 16/9) {
  .App.App--landscape .App-wrap {
    border-bottom: 1px solid $grey2;
  }
}

@media screen and (min-width: 320px) {
  .App.App--portrait {
    font-size: calc(16 * ((100vw / 360)));
  }
@media (min-aspect-ratio: 360/640) {
  .App.App--portrait {
    font-size: calc(16 * ((100vh / 640)));
  }
}
}



/* Typical tablet width is 768 */
// @media screen and (min-width: 767px) {
//   .App.App--portrait {
//     // font-size: calc(16 * ((100vw / 450)));
//     font-size: calc(100vh / 35);
//   }
// }


@media print {
  .App.App--landscape {
    width: 100%;
    height: auto;
    min-height: auto;
    background-color: white;

    .App-wrap {
      width: 100%;
      height: auto;
      min-height: auto;
      font-size: 16px;
      overflow: auto;
      border-style: none;
    }
  }
}