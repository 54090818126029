/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.AvatarCardLandscape {
	text-align: center;
	height: 100%;
	padding: vw-calc(35) vw-calc(45);
	background-color: #F1F9FF;
	@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(0, 0, 0, 0.16));
	&--back {
		padding: 0;
		// margin-top: vw-calc(45);
		// height: vw-calc(880);
		
		.AvatarImage {
			position: absolute;
			top: vw-calc(-30);
			left: vw-calc(178);
		}
		.AvatarCardLandscape-name {
			display: none;
		}	
		.AvatarCardLandscape-info {
			// height: vw-calc(300);
			padding: vw-calc(100) vw-calc(55) vw-calc(10) vw-calc(55);
			// background-color: #A4D0F1;
		}
		.AvatarCardLandscape-infoText {display: none;}
		.AvatarCardLandscape-infoText2,
		.AvatarCardLandscape-statsWrap {display: block;}
	}
}

.AvatarCardLandscape-name {
	width: 100%;
	text-align: center;
	font-size: vw-calc(50);
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;	
}

.AvatarCardLandscape-info {
	padding-top: vw-calc(20);
	text-align: left;
}
.AvatarCardLandscape-infoText {
	font-size: vw-calc(25);
	line-height: 1.2;
	> div {margin: 1em 0;}
}
.AvatarCardLandscape-infoText2 {
	display: none;
	font-size: vw-calc(25);
	margin-top: 1.5em;
	// color: white,
}

.AvatarCardLandscape-statsWrap {
	display: none;
	text-align: left;
	padding: vw-calc(20) vw-calc(55);
}
.AvatarCardLandscape-statsInfo {
	font-size: vw-calc(23);
	font-weight: bold;
}

.AvatarCardLandscape-stats {
	position: relative;
	margin-top: vw-calc(10);
	border-bottom: vw-calc(2) solid rgba(#61A4D8, 0.4);
	border-top: vw-calc(2) solid rgba(#61A4D8, 0.4);
}

.AvatarCardLandscape-stat {
	position: relative;
	width: 100%;
	height: vw-calc(65);
	padding-left: vw-calc(65);
	background-size: auto vw-calc(40);
	background-repeat: no-repeat;
	background-position: left 0 center;
	&:nth-last-child(1) {
		margin-bottom: vw-calc(10);

	}
	&--danish {background-image: url('../../assets/images/stats/danish.svg');}
	&--reading {background-image: url('../../assets/images/stats/reading.svg');}
	&--math {background-image: url('../../assets/images/stats/math.svg');}
	&--teamwork {background-image: url('../../assets/images/stats/teamwork.svg');}
	&--workEthic {background-image: url('../../assets/images/stats/workEthic.svg');}
}
.AvatarCardLandscape-statName {
	font-size: vw-calc(24);
	line-height: 2.7;
	span {
		display: inline-block;
		position: absolute;
		right: vw-calc(20);
		font-weight: bold;
	}
}

.AvatarCardLandscape-selectAvatar {
	position: absolute;
	width: 100%;
	height: vw-calc(65);
	bottom: vw-calc(40);
	left: 0;
	text-align: center;
}

