/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.GoalCard-title {
	position: relative;
	height: 2.5em;
	font-size: 1.25em;
	font-weight: bold;
	line-height: 1;
	text-align: center;
}

.GoalCard-image {	
	margin: 0 auto;
	width: 5.2em;
	height: 5.2em;
	border-radius: 4em;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url('../../assets/images/goals/default.svg');
	&--food_health {background-image: url('../../assets/images/goals/food_health.svg');}
	&--office_commerce {background-image: url('../../assets/images/goals/office_commerce.svg');}
	&--caregiving {background-image: url('../../assets/images/goals/caregiving.svg');}
	&--design_media {background-image: url('../../assets/images/goals/design_media.svg');}
	&--animals_nature_environment {background-image: url('../../assets/images/goals/animals_nature_environment.svg');}
	&--crafts_construction {background-image: url('../../assets/images/goals/crafts_construction.svg');}
	&--it_electronics {background-image: url('../../assets/images/goals/it_electronics.svg');}
	&--engineering_mechanics_machines {background-image: url('../../assets/images/goals/engineering_mechanics_machines.svg');}
	&--transport_logistics {background-image: url('../../assets/images/goals/transport_logistics.svg');}
}

.GoalCard-text {
	font-weight: normal;
	padding-top: 1em;
	margin-bottom: 1em;
}

.GoalCard-requirements {
	border-top: 0.05em solid rgba(#707070, 0.4);
	padding-top: 0.75em;
}

.GoalCard-requirementsTitle {
	font-weight: bold;
}

.GoalCard-requirement {
	position: relative;	
	padding-left: 1.9em;
	height: 1.5em;
	line-height: 1.5em;
	margin-top: 0.25em;
	&::before {
		content: "•";
		position: absolute;
		left: 0.25em;
		top: 0.02em;
		width: 1em;
		height: 1em;
		font-size: 2.32em;
		color: $red;
	}
}


.GoalCard-selectCard {
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 1em;
	left: 0;
}

// @media screen and (max-height: 668px) {
// 	.GoalCard-title {
// 		height: 2.3em;
// 	}

// 	.GoalCard-text {
// 		padding-top: 0.7em;
// 		margin-bottom: 0.7em;
// 	}	
// }