/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Start {
	position: relative;
	height: 100%;
	padding: 3.5em 2.25em 2em 2.25em;
	background-image: url('../../assets/images/background-stairs.jpg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.Start-text {
	position: relative;
	margin-top: 2em;
	margin-bottom: 3em;
	color: $text-dark;
	p {
		padding: 0 1.5em;
		font-size: 1.1875em;
		line-height: 1.35;
	}
}

.Start-footer {
	position: absolute;
	bottom: 2em;
	left: 0;
	right: 0;
	height: 1.625em;
	background-image: url('../../assets/images/vuc-logo.png');
	background-size: auto 100%;
	background-position: bottom center;
	background-repeat: no-repeat;
}

.Start.Start--landscape {
	padding: vw-calc(150) vw-calc(500) vw-calc(60) vw-calc(500);
	background-image: url('../../assets/images/background-landscape.jpg');
	background-size: cover;
	.Start-text {
		margin: vw-calc(60) auto vw-calc(130) auto;
		p {
			font-size: vw-calc(40);
		}
	}
	.Start-footer { 
		height: vw-calc(30);
		bottom: vw-calc(60);
	}
}