/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.PwaInfo {
	position: fixed;
	bottom: 0.5em;
	// width: 22.5em;
	width: 100%;
	padding: 0 1em 1em 1em;
	opacity: 0;
	-webkit-animation: fadeInPWA 0.5s ease-in 2s 1 forwards;
	animation: fadeInPWA 0.5s ease-in 2s 1 forwards;
	&--ios {
		.PwaInfo-icon {
			background-image: url('../../assets/images/icon-share-ios.svg');
		}
		.PwaInfo-text::after {
			content: "";
			position: absolute;
			bottom: -1.25em;
			left: calc(50% - 1em);
			height: 0;
			width: 0;
			border-top: 1.5em solid #7CC6FF;
			border-left: 1em solid transparent;
			border-right: 1em solid transparent;
		}
	}
	&--android {
		.PwaInfo-icon {
			background-image: url('../../assets/images/icon-menu-android.svg');
		}
	}
}



.PwaInfo-text {
	position: relative;
	text-align: center;
	color: $text-dark;
	padding: 1em 2.5em;
	background: linear-gradient(#C6E7FF, #7CC6FF);
	border-radius: 0.5em;
	p {
		margin: 0;
		font-size: 1.1875em; 
		line-height: 1.25;
		span {
			font-weight: 600;
		}
	}
}

.PwaInfo-icon {
	display: inline-block;
	width: 1em;
	height: 1.25em;
	background-size: auto 100%;
	background-position: center bottom;
	background-repeat: no-repeat;
}


@-webkit-keyframes fadeInPWA {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
@keyframes fadeInPWA {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
