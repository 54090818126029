/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Tutorial {
	position: absolute;
	width: 0;
	top: 0;
	width: 22.5em;
	left: calc(50% - 11.25em);
	// width: 100%;
	height: 0;
	background: transparent;
	z-index: 100;
	pointer-events: none;
	&--open {
		top: 0;
		bottom: 0;
		height: auto;
		pointer-events: all;
	}
	&--actionEnergy {
		left: calc((50% - 11.25em) * 2);
	}
}

.Tutorial-body {
	position: absolute;
	top: 0em;
	right: 0em;
	width: 18em;
	width: 80%;
	min-height: 6em;
	padding: 2.5em 1.5em 2em 1.5em;
	border-radius: 0.625em;
	background-image: linear-gradient(#C4E6FF, #7DC6FF);
	color: #516B6F;
	@include scale(0);
	@include origin(100%, 0%);
	&::after {
		content: "";
		position: absolute;
		top: -1em;
		height: 0;
		width: 0;
	}
	&--intro {
		@include scale(1);
		width: 20.5em;
		padding: 3em 1.5em 1.5em 1.5em;
		-webkit-animation: openTutorialIntro 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation: openTutorialIntro 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
	}
	&--goal {
		@include scale(1);
		right: 2em;
		top: 14.5em;
		&::after {
			right: 2em;
			border-bottom: 1.25em solid #C6E7FF;
			border-left: 1em solid transparent;
			border-right: 1em solid transparent;
		}
	}
	&.Tutorial-body--goal.Tutorial-body--open {
		-webkit-animation: openTutorial 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation: openTutorial 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
	}
	&--actionDrag {
		@include scale(1);
		right: 2.25em;
		top: 15.5em;	
		&::after {		
			left: 6.5em;
			top: -1.25em;
			border-bottom: 1.5em solid #C6E7FF;
			border-right: 1.75em solid transparent;
		}		
	}
	&--actionInfo {
		@include scale(1);
		right: 2.25em;
		top: 20.75em;
		&::after {
			left: 2em;
			top: -1.75em;
			border-bottom: 2em solid #C6E7FF;
			border-left: 0.25em solid transparent;
			border-right: 1.75em solid transparent;
		}		
	}
	&--actionEnergy {
		@include scale(1);
		right: 2.25em;
		top: 7.5em;
		&::after {
			right: 2em;
			top: -2em;
			border-bottom: 2.25em solid #C6E7FF;
			border-right: 0.5em solid transparent;
			border-left: 1.75em solid transparent;
		}		
	}
	&--actionColors {
		@include scale(1);
		text-align: left;
		right: 2.25em;
		top: 20em;
		&::after {
			right: 4em;
			border-bottom: 1.25em solid #C6E7FF;
			border-right: 1em solid transparent;
			border-left: 1em solid transparent;
		}			
	}
	&--actionOrder,
	&--actionOrder2 {
		@include scale(1);
		right: 2.25em;
		top: 20.5em;
		&::after {
			right: 0.5em;
			top: -2em;
			border-bottom: 2.25em solid #C6E7FF;
			border-right: 0.25em solid transparent;
			border-left: 1.75em solid transparent;
		}	
	}
	&--center {
		right: 2.25em;
		top: 15.5em;
		&::after {display: none;}
	}
	&--close {
		-webkit-animation: closeTutorial 0.25s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation: closeTutorial 0.25s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
	}

}

.Tutorial-close {
	position: absolute;
	top: 0em;
	right: 0em;
	width: 3em;
	height: 3em;
	background-image: url('../../assets/images/icon-close.svg');
	background-size: 1em 1em;
	background-position: top 0.75em right 0.5em;
	background-repeat: no-repeat;
	cursor: pointer;
}

.Tutorial-text {
	span.red {color: $red; font-weight: 700;}
	span.green {color: $green; font-weight: 700;}
	span.yellow {color: $yellow; font-weight: 700;}
}


.Tutorial-nextBtn {
	text-align: center;
	margin-top: 2em;
}

.Tutorial-page {
	margin-top: 1em;
	font-weight: bold;
}

@-webkit-keyframes openTutorialIntro {
	0% {
		-ms-transform: translate(0, 0) scale(0);
		-webkit-transform: translate(0, 0) scale(0);
		transform: translate(0, 0) scale(0);
	}
	100% {
		-ms-transform: translate(-1em, 12em) scale(1);
		-webkit-transform: translate(-1em, 12em) scale(1);
		transform: translate(-1em, 12em) scale(1);
	}
}
@keyframes openTutorialIntro {
	0% {
		-ms-transform: translate(0, 0) scale(0);
		-webkit-transform: translate(0, 0) scale(0);
		transform: translate(0, 0) scale(0);
	}
	100% {
		-ms-transform: translate(-1em, 12em) scale(1);
		-webkit-transform: translate(-1em, 12em) scale(1);
		transform: translate(-1em, 12em) scale(1);
	}
}


@-webkit-keyframes openTutorial {
	0% {
		-ms-transform: translate(2em, -14.5em) scale(0);
		-webkit-transform: translate(2em, -14.5em) scale(0);
		transform: translate(2em, -14.5em) scale(0);
	}
	100% {
		-ms-transform: translate(0, 0) scale(1);
		-webkit-transform: translate(0, 0) scale(1);
		transform: translate(0, 0) scale(1);
	}
}
@keyframes openTutorial {
	0% {
		-ms-transform: translate(2em, -14.5em) scale(0);
		-webkit-transform: translate(2em, -14.5em) scale(0);
		transform: translate(2em, -14.5em) scale(0);
	}
	100% {
		-ms-transform: translate(0, 0) scale(1);
		-webkit-transform: translate(0, 0) scale(1);
		transform: translate(0, 0) scale(1);
	}
}

@-webkit-keyframes closeTutorial {
	0% {		
		-ms-transform: translate(0, 0) scale(1);
		-webkit-transform: translate(0, 0) scale(1);
		transform: translate(0, 0) scale(1);
	}	
	100% {
		top: 0;
		right: 0;
		-ms-transform: translate(0, 0) scale(0);
		-webkit-transform: translate(0, 0) scale(0);
		transform: translate(0, 0) scale(0);
	}
}
@keyframes closeTutorial {
	0% {		
		-ms-transform: translate(0, 0) scale(1);
		-webkit-transform: translate(0, 0) scale(1);
		transform: translate(0, 0) scale(1);
	}	
	100% {
		top: 0;
		right: 0;
		-ms-transform: translate(0, 0) scale(0);
		-webkit-transform: translate(0, 0) scale(0);
		transform: translate(0, 0) scale(0);
	}
}

