/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Scrollbar2 {
	position: absolute;
	height: vw-calc(820);
	width: vw-calc(15);
	bottom: vw-calc(15);
	right: vw-calc(15);
	border-radius: vw-calc(10);
	background: rgba(#FFFFFF, 0.4);
	padding-top: vw-calc(120);
	cursor: pointer;
	z-index: 4;
}

.Scrollbar2-track {
	position: relative;
  width: 100%;
	height: 100%;
	height: vw-calc(580);
	// background: rgba(#FFFFFF, 0.4);
	border-radius: vw-calc(10);
}

.Scrollbar2-thumb {
	position: relative;
  width: 100%;
	height: vw-calc(240);
	background-color: #9AC1E0;
	border-radius: vw-calc(10);
}
