/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Logo {
	position: relative;
	margin: 0 auto;
	width: 10em;
	height: 10em;
	background-image: url('../../assets/images/logo.svg');
	background-size: contain;
	background-position: top center;
	background-repeat: no-repeat;
	&--loading {
		width: 15em;
		height: 15em;
		top: 10em;
		line-height: 15em;
	}
}

.Logo.Logo--landscape {
	width: vw-calc(265);
	height: vw-calc(255);
	line-height: vw-calc(1);
}