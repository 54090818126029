/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/variables';
html {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  // min-height: calc(1.7778 * 100vw);
	font-size: 16px;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 1em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background-color: transparent;
  // -webkit-overflow-scrolling: touch;
  touch-action: manipulation; // disables double-tap gestur -> no click delay by the browser
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

* {box-sizing: border-box;}

button {
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

ul, ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input[type='submit'] {
	@include appearance(none);
}

@media print {
	html {
    font-size: 16px;
    height: auto;
    min-height: auto;
    width: 100%;
  }
  body {
    width: 100%;
    height: auto;
    min-height: auto;
    position: relative;
    overflow: auto;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    background-color: white;
  }

  #root {
    width: 100%;
    height: auto;
    min-height: auto;
    overflow: auto;
  }
}

// @media screen and (min-width: 320px) {
//   html {
//     font-size: calc(16 * ((100vw / 360)));
//   }
// }

// @media screen and (min-width: 512px) {
//   html {
//     font-size: 22.75px;
//     min-height: auto;
//   }
//   .App.App--game {
//     width: 512px;
//     height: 910px;
//     min-height: 910px;
//     margin-left: calc((100vw - 512px) / 2);
//   }
// }