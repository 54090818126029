/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
$star1Delay: 1.1;
$star2Delay: 2.0;
$star3Delay: 2.8;

.PopupIcon {
	position: relative;
	display: inline-block;
	width: 4.5em;
	height: 4.5em;
	margin: 0 0.25em;
	text-align: center;
	.star-outline,
	.star-filled {
		position: absolute;
		top: 0.25em;
		left: 0.25em;
		width: 4em;
		height: 4em;
		opacity: 1;
		z-index: 1;
	}
	.star-filled {
		opacity: 0;
		z-index: 2;
	}
	&--star:nth-of-type(1) {
		.star-outline {
			-webkit-animation: fadeOut 0.2s #{$star1Delay - 0.1}s both;
			animation: fadeOut 0.2s #{$star1Delay - 0.1}s both;
		}
		.star-filled {
			-webkit-animation: fadeIn 0.2s #{$star1Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star1Delay}s 1 forwards;
			animation: fadeIn 0.2s #{$star1Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star1Delay}s 1 forwards;
		}
	}
	&--star:nth-of-type(2) {
		.star-outline {
			-webkit-animation: fadeOut 0.2s #{$star2Delay - 0.1}s both;
			animation: fadeOut 0.2s #{$star2Delay - 0.1}s both;
		}
		.star-filled {
			-webkit-animation: fadeIn 0.2s #{$star2Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star2Delay}s 1 forwards;
			animation: fadeIn 0.2s #{$star2Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star2Delay}s 1 forwards;
		}
	}
	&--star:nth-of-type(3) {
		.star-outline {
			-webkit-animation: fadeOut 0.2s #{$star3Delay - 0.1}s both;
			animation: fadeOut 0.2s #{$star3Delay - 0.1}s both;
		}
		.star-filled {
			-webkit-animation: fadeIn 0.2s #{$star3Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star3Delay}s 1 forwards;
			animation: fadeIn 0.2s #{$star3Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star3Delay}s 1 forwards;
		}
	}

	&--star-outline {
		.star-filled {
			display: none;
		}
	}
}


.PopupIcon-animation {
	position: absolute;
	width: 10em;
	height: 10em;
	border-radius: 10em;
	top: -2.75em;
	left: -2.75em;
	z-index: 0;
	overflow: hidden;
}
.PopupIcon-animationObject {
	position: absolute;
	width: 0.3em;
	height: 0.3em;
	border-radius: 1em;
	opacity: 0;
}

$objects: 70;
$maxStars: 3;
@for $i from 1 through $objects {
	$angle: (random(360) * pi()) / 180.0;
	$length: random(5.0);
	$left1: (0.95 * $length * cos($angle)) + 5;
	$left2: ($length * cos($angle)) + 5;
	$top1: 5 - (0.95 * $length * sin($angle));
	$top2: 5 - ($length * sin($angle));
	@-webkit-keyframes anim-#{$i} {
		0% {
			top: 50%;
			left: 50%;
			opacity: 1;
		}
		90% {
			top: #{$top1}em;
			left: #{$left1}em;
			opacity: 0.9;
		}
		100%{
			top: #{$top2}em;
			left: #{$left2}em;
			opacity: 0;
		}
	}
	@keyframes anim-#{$i} {
		0% {
			top: 50%;
			left: 50%;
			opacity: 1;
		}
		90% {
			top: #{$top1}em;
			left: #{$left1}em;
			opacity: 0.9;
		}
		100%{
			top: #{$top2}em;
			left: #{$left2}em;
			opacity: 0;
		}
	}
	@for $j from 1 through $maxStars {
		.PopupIcon--star {
			&:nth-child(#{$j}) {
				$delay: $star1Delay;
				$color: $yellow;
				@if ($i > 50) { $color: white;}
				@if ($j == 2) { $delay: $star2Delay; }
				@if ($j == 3) { $delay: $star3Delay; }
				.PopupIcon-animationObject {
					&:nth-child(#{$i}){
						left: $i * 0.5em;
						-webkit-animation-duration: 0.8s;
						animation-duration: 0.8s;
						-webkit-animation-delay: #{$delay}s;
						animation-delay: #{$delay}s;
						-webkit-animation-name: anim-#{$i};
						animation-name: anim-#{$i};
						-webkit-animation-iteration-count: 1;
						animation-iteration-count: 1;
						-webkit-animation-direction: alternate;
						animation-direction: alternate;
						-webkit-animation-fill-mode: forwards;
						animation-fill-mode: forwards;
						-webkit-animation-timing-function: ease-out;
						animation-timing-function: ease-out;
						background: $color
						}
					}
				}
			}
		}
}

@-webkit-keyframes fadeIn {
	0%   {opacity: 0;}
	100% {opacity: 1;}
}
@keyframes fadeIn {
	0%   {opacity: 0;}
	100% {opacity: 1;}
}

@-webkit-keyframes fadeOut {
	0%   {opacity: 1;}
	100% {opacity: 0;}
}
@keyframes fadeOut {
	0%   {opacity: 1;}
	100% {opacity: 0;}
}

@-webkit-keyframes bounceStar {
	0%   {@include scale(1);}
	20%  {@include scale(.2);}
	30%  {@include scale(1.2);}
	60%  {@include scale(.8);}
	100% {@include scale(1);}
}
@keyframes bounceStar {
	0%   {@include scale(1);}
	20%  {@include scale(.2);}
	30%  {@include scale(1.2);}
	60%  {@include scale(.8);}
	100% {@include scale(1);}
}

