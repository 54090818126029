/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Audio {
	position: absolute;
	top: 0em;
	left: 0em;
	width: 4em;
	height: 4em;
	&--right {
		left: auto;
		right: -1.2em;
	}
	&--landscape {
		width: vw-calc(100);
		height: vw-calc(100);
		.Audio-playPauseBtn {
			background-size: vw-calc(50) auto;
			background-position: center center;
		}
	}
}

.Audio-playPauseBtn {
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/icon-audio.svg');
	background-size: 1.5em auto;
	background-position: top 0.5em left 0.5em;
	background-repeat: no-repeat;
	// &--loading {display: none;}
}