/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Button {
	display: inline-block;
	width: 6.25em;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	color: white;
	background-color: #4B6E72;
	background-image: linear-gradient(#4B6E72, #3F565A);
	padding: 0.25em 0;
	border: 0.0625em solid #F0F0F0;
	border-radius: 1em;
	text-decoration: none;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;	
	cursor: pointer;
	@include no-select();
	@include box-shadow-custom(0, 0.1875em, 0.1875em, 0, rgba(#223E43, .6)); 
	&:hover,
	&:focus,
	&:active {
		background-color: darken(#4B6E72, 5%);
		background-image: linear-gradient(darken(#4B6E72, 5%), darken(#3F565A, 5%));
	}

	&--result {
		width: auto;
		padding: 0.25em 0.75em;
	}

	&--disabled {
		cursor: not-allowed;
		background: grey;
		&:hover,
		&:focus,
		&:active {
			background: grey;
		}
	}

	&--loading {
		background-image: url('../../assets/images/icon-loading.svg');
		background-size: 1.75em auto;
		background-position: right 0.2em center;
		background-repeat: no-repeat;
		&:hover,
		&:focus,
		&:active {
			background-image: url('../../assets/images/icon-loading.svg'), linear-gradient(darken(#4B6E72, 5%), darken(#3F565A, 5%));
			background-size: 1.75em auto, auto;
			background-position: right 0.2em center, center;
		}
	}

	&--start {
		width: 10em;
		background: linear-gradient(#F7B348, #F68D26);
		span {
			font-size: 1.5625em;
			line-height: 1.4em;
		}
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#F7B348, 5%), darken(#F68D26, 5%));
		}
	}

	&--clearCache {
		position: absolute;
		top: 0.5em;
		left: 0.5em;
	}

	&--result, 
	&--part1,
	&--part2 {
		position: relative;
		text-transform: none;
		padding-top: 0.5em;
		span {
			font-size: 1.25em;
			font-weight: 700;
		}
		&.Button--disabled {
			opacity: 0.5;
			background-image: none;
			background: grey;
			&:hover,
			&:focus,
			&:active {
				background-image: none;
				background: grey;
			}			
		}		
		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-repeat: no-repeat;
			pointer-events: none;
		}
	}

	&--result {
		width: 10em;
		height: 6.5em;
		background: linear-gradient(#F8E3A1, #FDD867);
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#F8E3A1, 5%), darken(#FDD867, 5%));
		}
		&::after {
			background-image: url('../../assets/images/icon-btn-result.svg');
			background-size: 3.25em auto;
			background-position: top 2.5625em center;
		}
	}

	&--part1 {
		width: 9em;
		height: 5em;
		background: linear-gradient(#F7B348, #F68D26);
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#F7B348, 5%), darken(#F68D26, 5%));
		}
		&::after {
			background-image: url('../../assets/images/icon-btn-part1.svg');
			background-size: 2em auto;
			background-position: top 2.5625em center;
		}
	}

	&--part2 {
		width: 9em;
		height: 5em;
		background: linear-gradient(#46CAB6, #5D868B);
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#46CAB6, 5%), darken(#5D868B, 5%));
		}
		&::after {
			background-image: url('../../assets/images/icon-btn-part2.svg');
			background-size: 2em auto;
			background-position: top 2.5625em center;
		}
	}

	&--goBack,
	&--goBack2 {
		position: fixed;
		left: 0;
		top: 0;
		width: 2em;
		height: 2em;
		z-index: 10;
		padding: 0;
		background-color: transparent;
		background-image: url('../../assets/images/icon-back.svg');
		background-position: top 0.5em left 0.75em;
		background-size: auto 1.3125em;
		background-repeat: no-repeat;
		border-radius: none;
		border-style: none;
		cursor: pointer;
		@include box-shadow-custom(0, 0, 0, 0, rgba(#000, 0)); 
		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
			background-image: url('../../assets/images/icon-back.svg');
		}
	}

	&--help {
		position: fixed;
		right: 0;
		top: 0;
		width: 2.5em;
		height: 2.5em;
		z-index: 10;
		padding: 0;
		background-color: transparent;
		background-image: url('../../assets/images/icon-question.svg');
		background-position: top 0.5em right 0.5em;
		background-size: auto 1.625em;
		background-repeat: no-repeat;
		border-radius: none;
		border-style: none;
		cursor: pointer;
		@include box-shadow-custom(0, 0, 0, 0, rgba(#000, 0)); 
		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
			background-image: url('../../assets/images/icon-question.svg');
		}
	}

	&--prev {
		// background-color: #DBDBDB;
		background: url('../../assets/images/icon-arrow-left.svg') no-repeat, linear-gradient(#4B6E72, #3F565A);
		// background-image: url('../../assets/images/icon-arrow-left.svg');
		background-position: 6% 0.65em, top left;
		text-align: left;
		padding-left: 1.2em;
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#4B6E72, 5%);
			background: url('../../assets/images/icon-arrow-left.svg') no-repeat, linear-gradient(darken(#4B6E72, 5%), darken(#3F565A, 5%));
			background-position: 6% 0.65em, top left;
		}
	}

	&--next {
		background: url('../../assets/images/icon-arrow-right.svg') no-repeat, linear-gradient(#4B6E72, #3F565A);
		background-position: 92%, top left;
		text-align: left;
		padding-left: 1.1em;
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#4B6E72, 5%);
			background: url('../../assets/images/icon-arrow-right.svg') no-repeat, linear-gradient(darken(#4B6E72, 5%), darken(#3F565A, 5%));
			background-position: 92%, top left;
		}
		&.Button--disabled {
			background-color: #DBDBDB;
			background-image: url('../../assets/images/icon-arrow-right.svg');
			background-position: 92%;
			background-repeat: no-repeat;
		}
	}

	&--green {
		background: linear-gradient(#4DDBC5, $green);
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#4DDBC5, 5%), darken($green, 5%));
		}
	}

	&--red {
		background: linear-gradient(#FC6C70, $red);
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#FC6C70, 5%), darken($red, 5%));
		}
	}

	&--startChallenge {
		width: 7.5em;
		border-radius: 0;
		border-top-left-radius: 1em;
		border: 0.125em solid white;
		border-right: none;
		background: linear-gradient(#3BB6A3, #1F907F);
		@include box-shadow-custom(0, 0.1875em, 0.375em, 0, rgba(#000, .33)); 
		&.Button--inactive {
			border: none;
			border-bottom: 0.125em solid #CCCCCC;
			background: linear-gradient(#AAAAAA, #CCCCCC);
			
		}
	}


	&--confirm-buy-action,
	&--buy-action-failed {
		font-size: 1.25em;
		line-height: 2.2;
		padding: 0 1em;
		background-color: $red;
	}
}


// @media screen and (min-width: 512px) {
// 	.Button.Button--goBack,
// 	.Button.Button--help {
// 		position: absolute;
// 	}
// }


.Button.Button--landscape {
	border-radius: vw-calc(16);
	border-width: vw-calc(1);
	padding: 0;
	width: vw-calc(300);
	height: vw-calc(60);
	@include box-shadow-custom(0, vw-calc(3), vw-calc(3), 0, rgba(#223E43, .6)); 
	span {
		display: inline-block;
		vertical-align: top;
		font-size: vw-calc(30);
		line-height: 2;	
	}

	&.Button--loading {
		background-size: vw-calc(50) auto;
		background-position: right vw-calc(10) center;
		&:hover,
		&:focus,
		&:active {
			background-size: vw-calc(50) auto, auto;
			background-position: right vw-calc(10) center, center;
		}
	}


	/* Start page */
	&.Button--start {
		width: vw-calc(465);
		height: vw-calc(120);
		span {
			font-size: vw-calc(40);
			line-height: 3;
		}
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#F7B348, 5%), darken(#F68D26, 5%));
		}
	}
	&.Button--cookie {
		text-transform: none;
		width: vw-calc(250);
		span {font-size: vw-calc(30);}
	}
	/* Home page */
	&.Button--result, 
	&.Button--part1,
	&.Button--part2 {
		width: vw-calc(465);
		height: vw-calc(120);
		padding: 0;
		span {
			font-size: vw-calc(40);
			line-height: 3;
			padding-left: vw-calc(120);
			text-align: left;
		}
		&::after {
			background-size: auto vw-calc(70);
			background-position: left vw-calc(50) center;
			background-repeat: no-repeat;
		}
	}
	&.Button--result::after {background-image: url('../../assets/images/icon-resultBtn.svg');}
	&.Button--part1::after {background-image: url('../../assets/images/icon-gameBtn.svg');}
	&.Button--part2::after {background-image: url('../../assets/images/icon-planBtn.svg');}


	/* Back btn */
	&.Button--goBack,
	&.Button--goBack2 {
		position: absolute;
		width: vw-calc(75);
		height: vw-calc(95);
		background-image: url('../../assets/images/icon-back.svg');
		background-position: left vw-calc(20) center;
		background-size: auto vw-calc(55);
		@include box-shadow-custom(0, 0, 0, 0, rgba(#000, 0)); 
	}
	&.Button--goBack2 {
		height: vw-calc(170);
		background-image: url('../../assets/images/icon-back-white.svg');
	}

	/* Help btn */
	&.Button--help {
		position: absolute;
		width: vw-calc(95);
		height: vw-calc(95);
		background-image: url('../../assets/images/icon-question.svg');
		background-position: center center;
		background-size: auto vw-calc(55);
		@include box-shadow-custom(0, 0, 0, 0, rgba(#000, 0)); 
	}

	/* Start challenge btn */
	&.Button--startChallenge {
		width: 100%;
		height: vw-calc(60);
		border-radius: vw-calc(10);
		border: vw-calc(2) solid white;
		border-right: none;
		background: linear-gradient(#3BB6A3, #1F907F);
		@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .33)); 
		&.Button--inactive {
			border: none;
			background: linear-gradient(#AAAAAA, #CCCCCC);		
		}
	}

	&.Button--prev {
		width: vw-calc(200);
		padding-right: vw-calc(20);
		text-align: right;
		background-position: 8%, top left;

	}
	&.Button--next {
		width: vw-calc(200);
		padding-left: vw-calc(20);
	}

	&.Button--evaluateActions {
		width: vw-calc(190);
		height: vw-calc(85);
		padding: 0;
		vertical-align: middle;
		background: linear-gradient(#4DDBC5, $green);
		margin-bottom: 0.1em;
		span {line-height: 2.83;}
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#4DDBC5, 5%), darken($green, 5%));
		}
		
	}

	&.Button--disabled,
	&.Button--inactive {
		cursor: not-allowed;
		background: grey;
		&:hover,
		&:focus,
		&:active {
			background: grey;
		}
	}

	&.Button--diplomaPrint,
	&.Button--diplomaEmail,
	&.Button--diplomaExit {
		position: relative;
		display: block;
		width: vw-calc(260);
		height: vw-calc(85);
		border-radius: vw-calc(16);
		margin: 0 0 vw-calc(50) 0;
		padding-left: vw-calc(40);
		background: linear-gradient(#3AB5A2, #209180);
		border-style: none;
		text-align: left;
		span {
			font-size: vw-calc(35);
			line-height: 2.43;	
		}
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#3AB5A2, 5%), darken(#209180, 5%));
		}
		&::after {
			content: "";
			width: vw-calc(85);
			height: vw-calc(85);
			position: absolute;
			top: 0;
			right: 0;
			background-size: vw-calc(40) auto;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}
	&.Button--diplomaPrint::after {background-image: url('../../assets/images/icon-print.svg');}
	&.Button--diplomaEmail::after {background-image: url('../../assets/images/icon-mail.svg');}
	&.Button--diplomaExit {
		background: linear-gradient(#F6B046, #F68E26);
		&:hover,
		&:focus,
		&:active {
			background: linear-gradient(darken(#F6B046, 5%), darken(#F68E26, 5%));
		}
		&::after {background-image: url('../../assets/images/icon-exit.svg');}
	}
}