/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';

.ChallengeAction-dragLayer {
	position: fixed;
	pointer-events: none;
	z-index: 10;
	left: -6.625em; // [width - dragHandle-width]
	left: 0;
	top: 0em;
	width: 10.5em;
	height: 4.25em;
}