/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.QuizQuestions {
	padding-bottom: 1em;
	overflow: hidden;
	.Quiz-question {
		font-size: 1.250em;
		text-align: left;
		margin: 0 1em;
		height: 3.8em;
	}

	.Quiz-category {
		position: relative;
		text-align: left;
		margin-bottom: 1em;
	}

	.Quiz-category-icon {
		display: inline-block;
		width: 2.5em;
		height: 2.5em;
		margin: 1em 0 0 1em;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;

		&--danish {background-image: url('../../assets/images/stats/danish.svg');}
		&--reading {background-image: url('../../assets/images/stats/reading.svg');}
		&--math {background-image: url('../../assets/images/stats/math.svg');}
		&--teamwork {
			background-image: url('../../assets/images/stats/teamwork.svg');
			width: 2.7em;
			height: 2.5em;
		}
		&--workEthic {background-image: url('../../assets/images/stats/workEthic.svg');}
	}

	.Quiz-category-name {
		position: absolute;
		left: 4.3em;
		top: 1.55em;
		font-weight: 700;
	}

	.Quiz-category-index {
		position: absolute;
		right: 0.5em;
		top: 0.5em;
	}

	.Quiz-answers {
		width: 16.8em;
		height: 3.563em;
		border: solid $text-dark 0.05em;
		border-radius: 0.375em;
		padding: 0.313em 0;
		margin: 0 auto;
	}

	.Quiz-answer {
		display: inline-block;
		width: 2.3em;
		height: 2.3em;
		color: $orange;
		font-weight: 700;
		font-size: 1.250em;
		line-height: 2.3em;
		margin: 0 0.14em;
		@include box-shadow-inset(0.094em, $text-darkest);
		border-radius: 0.2em;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			border-radius: 0.2em;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $orange;
			@include scale(0);
			@include transition(all, 0.3s, ease);
		}
		&.filled {
			&:before {
				@include scale(1);
			}
		}
	}
	
	.Quiz-card {
		width: 20.5em;
		height: 15em;
		margin: 1.2em auto;
		border-radius: 0.313em;
		background-color: white;
		@include box-shadow();

		&.sliding {
			&--next {
				-webkit-animation: nextSlideout 0.2s 0s ease-in, nextSlidein 0.4s 0.2s ease-out;
				animation: nextSlideout 0.2s 0s ease-in, nextSlidein 0.4s 0.2s ease-out;
				@-webkit-keyframes nextSlidein {
					from {transform: translateX(100%);}
					to {transform: translateX(0%);}
				}
				@keyframes nextSlidein {
					from {transform: translateX(100%);}
					to {transform: translateX(0%);}
				}
				@-webkit-keyframes nextSlideout {
					from {transform: translateX(0%);}
					to {transform: translateX(-100%);}

				}
				@keyframes nextSlideout {
					from {transform: translateX(0%);}
					to {transform: translateX(-100%);}

				}
			}
			&--prev {
				-webkit-animation: prevSlideout 0.2s ease-in, prevSlidein 0.4s 0.2s ease-out;
				animation: prevSlideout 0.2s ease-in, prevSlidein 0.4s 0.2s ease-out;
				@-webkit-keyframes prevSlidein {
					from {transform: translateX(-100%);}
					to {transform: translateX(0%);}
				}
				@keyframes prevSlidein {
					from {transform: translateX(-100%);}
					to {transform: translateX(0%);}
				}
				@-webkit-keyframes prevSlideout {
					from {transform: translateX(0%);}
					to {transform: translateX(100%);}
				}
				@keyframes prevSlideout {
					from {transform: translateX(0%);}
					to {transform: translateX(100%);}
				}
			}
		}
	}
}



.QuizQuestions.QuizQuestions--landscape {
	padding-bottom: vw-calc(20);

	.Quiz-card {
		width: vw-calc(795);
		height: vw-calc(485);
		margin: vw-calc(30) auto;
		border-radius: vw-calc(5);
		background-color: white;
		@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
	}

	.Quiz-category {margin-bottom: vw-calc(20);}
	.Quiz-category-icon {
		vertical-align: top;
		width: vw-calc(85);
		height: vw-calc(85);
		margin: vw-calc(25) 0 0 vw-calc(30);
		&--teamwork {width: vw-calc(90);}
	}
	.Quiz-category-name {
		font-size: vw-calc(35);
		left: 4.3em;
		top: 1.43em;
	}
	.Quiz-category-index {
		font-size: vw-calc(25);
		right: 0.75em;
		top: 0.75em;
	}

	.Quiz-question {font-size: vw-calc(30);}
	
	.Quiz-answers {
		width: vw-calc(550);
		height: vw-calc(120);
		border: solid $text-dark vw-calc(2);
		border-radius: vw-calc(6);
		padding: vw-calc(10) 0;
		
	}

	.Quiz-answer {
		font-size: vw-calc(40);
		display: inline-block;
		vertical-align: top;
		width: 2.3em;
		height: 2.3em;		
		line-height: 2.3em;	
		margin: 0 0.2em;
		@include box-shadow-inset(0.094em, $text-darkest);
		border-radius: 0.15em;
		cursor: pointer;
		&:before {
			border-radius: 0.15em;
		}
		&.filled {color: white;}
	}
}