/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
@mixin animateStat($stat, $from, $to) {
	-webkit-animation: bounce-#{$stat}-#{$from}-#{$to} 0.8s cubic-bezier(0.280, 0.840, 0.420, 1) 0.1s 1 forwards;
	animation: bounce-#{$stat}-#{$from}-#{$to} 0.8s cubic-bezier(0.280, 0.840, 0.420, 1) 0.1s 1 forwards;
}

@mixin animateStat2($stat, $from, $to) {
	-webkit-animation: bounce2-#{$stat}-#{$from}-#{$to} 0.8s cubic-bezier(0.280, 0.840, 0.420, 1) 0.1s 1 forwards;
	animation: bounce2-#{$stat}-#{$from}-#{$to} 0.8s cubic-bezier(0.280, 0.840, 0.420, 1) 0.1s 1 forwards;
}

.AvatarStats-stats {
	position: absolute;
	height: 3em;
	left: 0.75em;
	right: 0.75em;
	bottom: 0;
	border-top: 0.125em solid rgba(#4B6E72, 0.1);
	overflow: hidden;
	&--landscape {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		height: 100%;
		width: vw-calc(665);
		padding: vw-calc(10) 0;
		border-style: none;
		left: auto;
		right: auto;
		.AvatarStats-stat {
			// font-size: vw-calc(40);
			width: vw-calc(131);
			height: 100%;
			border-style: none;
			border-right: vw-calc(2) solid #61A4D8;
			&:nth-child(1) {border-right: vw-calc(2) solid #61A4D8;}
			margin-right: 0;
			background-size: auto vw-calc(50);
			background-position: center center;
			span {
				display: inline-block;
				vertical-align: top;
				font-size: vw-calc(20);
				// font-size: 0.5em;
				font-weight: bold;
				line-height: 1;
				padding-top: 4em;
				padding-right: 1em;
			}
			&--hovereffect:hover {
				background-size: auto vw-calc(55);
			}
		}
		.AvatarStats-statName {
			font-size: vw-calc(20);
			// font-size: 0.5em;
			text-align: center;
		}
	}
}

.AvatarStats-stat {
	display: inline-block;
	vertical-align: middle;
	width: 3.625em;
	height: 3em;
	border-left: 0.03em solid rgba(#707070, 0.4);
	margin-right: 0.5em;
	text-align: right;
	&:nth-child(1) {border-style: none;}
	span {
		display: inline-block;
		font-size: 0.8125em;
		font-weight: bold;
		line-height: 3.7em;
		padding-right: 0.1em;
	}
	background-size: auto 1.5em;
	background-repeat: no-repeat;
	background-position: center center;
	&--clickable {
		@include no-select();
		cursor: pointer;
	}
}

$stats: "danish", "reading", "math", "teamwork", "workEthic";
$colors: "red", "yellow", "green";

/* Static colors of stats */
@each $stat in $stats {
	.AvatarStats-stat.AvatarStats-stat--#{$stat} {
		background-image: url('../../assets/images/stats/' + $stat + '.png');
	}
	@each $color in $colors {
		.AvatarStats-stat.AvatarStats-stat--#{$stat}.AvatarStats-stat--#{$color} {
			background-image: url('../../assets/images/stats/' + $stat + '-' + $color + '.png');
		}
	}
}

$animationSteps: "0", "10", "11", "30", "60", "100";
$animationSizes: "1.5", "1", "1", "1.75", "1.25", "1.5";
$animationSizes2: "0.5", "0.2", "0.2", "0.6", "0.4", "0.5";

/* Animated colors of stat */
@each $stat in $stats {
	@each $c1 in $colors {
		@each $c2 in $colors {
			@if ($c1 != $c2) {
				.AvatarStats-stat.AvatarStats-stat--#{$stat}.AvatarStats-stat--#{$c1}to#{$c2} {
					background-image: url('../../assets/images/stats/' + $stat + '-' + $c1 + '.png');
					@include animateStat($stat, $c1, $c2);
				}
				.AvatarStats-stat.AvatarStats-stat--landscape.AvatarStats-stat--#{$stat}.AvatarStats-stat--#{$c1}to#{$c2} {
					@include animateStat2($stat, $c1, $c2);
				}
			}
		}
	}
	.AvatarStats-stat.AvatarStats-stat--#{$stat}.AvatarStats-stat--defaulttogreen {
		background-image: url('../../assets/images/stats/' + $stat + '.png');
		@include animateStat($stat, default, green);
	}
	.AvatarStats-stat.AvatarStats-stat--landscape.AvatarStats-stat--#{$stat}.AvatarStats-stat--defaulttogreen {
		@include animateStat2($stat, default, green);
	}
	.AvatarStats-stat.AvatarStats-stat--#{$stat}.AvatarStats-stat--defaulttored {
		background-image: url('../../assets/images/stats/' + $stat + '.png');
		@include animateStat($stat, default, red);
	}
	.AvatarStats-stat.AvatarStats-stat--landscape.AvatarStats-stat--#{$stat}.AvatarStats-stat--defaulttored {
		@include animateStat2($stat, default, red);
	}
}

@each $stat in $stats {
	@each $color1 in $colors {
		@each $color2 in $colors {
			$c1: $color1;
			$c2: $color2;
			$path1: '../../assets/images/stats/' + $stat + '-' + $c1 + '.png';
			$path2: '../../assets/images/stats/' + $stat + '-' + $c2 + '.png';
			@if ($color1 == $color2 and $color1 == 'green') {
				$c1: 'default';
				$c2: 'green';
				$path1: '../../assets/images/stats/' + $stat + '.png';
				$path2: '../../assets/images/stats/' + $stat + '-' + $c2 + '.png';
			}
			@if ($color1 == $color2 and $color1 == 'red') {
				$c1: 'default';
				$c2: 'red';
				$path1: '../../assets/images/stats/' + $stat + '.png';
				$path2: '../../assets/images/stats/' + $stat + '-' + $c2 + '.png';
			}
			@-webkit-keyframes bounce-#{$stat}-#{$c1}-#{$c2} {
				#{nth($animationSteps, 1) + "%"} { background-size: auto #{nth($animationSizes, 1) + 'em'}; background-image: url($path1);}
				#{nth($animationSteps, 2) + "%"} { background-size: auto #{nth($animationSizes, 2) + 'em'}; background-image: url($path1);}
				#{nth($animationSteps, 3) + "%"} { background-size: auto #{nth($animationSizes, 3) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 4) + "%"} { background-size: auto #{nth($animationSizes, 4) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 5) + "%"} { background-size: auto #{nth($animationSizes, 5) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 6) + "%"} { background-size: auto #{nth($animationSizes, 6) + 'em'}; background-image: url($path2);}
			}
			@keyframes bounce-#{$stat}-#{$c1}-#{$c2} {
				#{nth($animationSteps, 1) + "%"} { background-size: auto #{nth($animationSizes, 1) + 'em'}; background-image: url($path1);}
				#{nth($animationSteps, 2) + "%"} { background-size: auto #{nth($animationSizes, 2) + 'em'}; background-image: url($path1);}
				#{nth($animationSteps, 3) + "%"} { background-size: auto #{nth($animationSizes, 3) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 4) + "%"} { background-size: auto #{nth($animationSizes, 4) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 5) + "%"} { background-size: auto #{nth($animationSizes, 5) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 6) + "%"} { background-size: auto #{nth($animationSizes, 6) + 'em'}; background-image: url($path2);}
			}

			@-webkit-keyframes bounce2-#{$stat}-#{$c1}-#{$c2} {
				#{nth($animationSteps, 1) + "%"} { background-size: auto #{nth($animationSizes2, 1) + 'em'}; background-image: url($path1);}
				#{nth($animationSteps, 2) + "%"} { background-size: auto #{nth($animationSizes2, 2) + 'em'}; background-image: url($path1);}
				#{nth($animationSteps, 3) + "%"} { background-size: auto #{nth($animationSizes2, 3) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 4) + "%"} { background-size: auto #{nth($animationSizes2, 4) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 5) + "%"} { background-size: auto #{nth($animationSizes2, 5) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 6) + "%"} { background-size: auto #{nth($animationSizes2, 6) + 'em'}; background-image: url($path2);}
			}
			@keyframes bounce2-#{$stat}-#{$c1}-#{$c2} {
				#{nth($animationSteps, 1) + "%"} { background-size: auto #{nth($animationSizes2, 1) + 'em'}; background-image: url($path1);}
				#{nth($animationSteps, 2) + "%"} { background-size: auto #{nth($animationSizes2, 2) + 'em'}; background-image: url($path1);}
				#{nth($animationSteps, 3) + "%"} { background-size: auto #{nth($animationSizes2, 3) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 4) + "%"} { background-size: auto #{nth($animationSizes2, 4) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 5) + "%"} { background-size: auto #{nth($animationSizes2, 5) + 'em'}; background-image: url($path2);}
				#{nth($animationSteps, 6) + "%"} { background-size: auto #{nth($animationSizes2, 6) + 'em'}; background-image: url($path2);}
			}
		}
	}
}