/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/variables';
.BackupAdmin {
	position: relative;
	font-size: 16px;
	padding: 3em 1.25em;
	text-align: left;
	input {
		display: inline-block;
		vertical-align: middle;
		width: 20em;
		font-size: 1em;
	}
	textarea {
		width: 20em;
		height: 15em;
		font-size: 1em;
	}
}

.BackupAdmin-title {
	font-weight: bold;
	font-size: 1.125em;
	margin: 1em 0;
}

.BackupAdmin-section {
	width: 38em;
	max-width: 90%;
}

.BackupAdmin-backBtn {
	position: absolute;
	top: 0.8em;
	left: 1.25em;
	display: inline-block;
	background-color: lightgrey;
	padding: 0.5em 0.9em;
	border-radius: 0.3em;
	font-weight: bold;
	margin: 0;
	cursor: pointer;
	&:hover {background-color: darken(lightgrey, 5%);}
}

.BackupAdmin-action,
.BackupAdmin-uploadFile {
	width: 30em;
	display: block;
	margin-top: 1em;
	background-color: lightgrey;
	padding: 0.5em 0.3em;
	border-radius: $border-radius;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	&:hover {background-color: darken(lightgrey, 5%);}
	&.loading {
		background-image: url('../../../assets/images/icon-spin.svg');
		background-size: 2em auto;
		background-position: right 0.25em center;
		background-repeat: no-repeat;
	}
}

.BackupAdmin-uploadFile {
	width: 20em;
	padding: 0.5em 0.3em;
}