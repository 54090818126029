/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.ChallengeAction {
	position: relative;
	width: 10.4em;
	height: 4.25em;
	border-radius: 0.625em;
	background-image: url('../../assets/images/challenge/action-bg.png');
	background-size: 100% auto;
	background-position: bottom right;
	cursor: pointer;
	&--green {background-image: url('../../assets/images/challenge/action-bg-green.png');}
	&--red {background-image: url('../../assets/images/challenge/action-bg-red.png');}
	&--blue {
		color: white;
		background-image: url('../../assets/images/challenge/action-bg-blue.png');
		-webkit-animation-name: bounce;
		animation-name: bounce;
		-webkit-animation-duration: .4s;
		animation-duration: .4s;
		-webkit-animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
		animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
	}
	&--yellow {
		background-image: url('../../assets/images/challenge/action-bg-yellow.png');
		.ChallengeAction-energy {
			background-image: url('../../assets/images/challenge/icon-energy-small-yellow.svg');
		}
		.ChallengeAction-moveIcon {
			background-image: url('../../assets/images/challenge/icon-move-yellow.svg');
		}
	}
	&--shaking {
		background-image: url('../../assets/images/challenge/action-bg-yellow.png');
		-webkit-animation-name: shake;
		animation-name: shake;
		-webkit-animation-duration: .4s;
		animation-duration: .4s;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	&--selected {
		&::after {
			content: "";
			position: absolute;
			left: calc(50% - 1.25em);
			top: -0.85em;
			width: 2.5em;
			height: 0.95em;
			background-image: url('../../assets/images/challenge/action-arrow.svg');
			background-size: contain;
			background-position: bottom center;
			background-repeat: no-repeat;
		}
		.ChallengeAction-moveIcon {
			@include rotate(90deg);
		}
	}
	// &--disabled {
		// cursor: not-allowed;
	// }
	&--placeholder {
		background-image: none;
		opacity: 0.4;
		background: linear-gradient(#E2DDDD, #EDEDED);
		cursor: default;
	}
}

.ChallengeAction-body {
	position: relative;
	height: 100%;
	padding: 0.8em 3em 0.8em 0.7em;
	text-align: left;
	border-radius: 0.625em;
	@include no-select();
	span {
		display: inline-block;
		font-weight: normal;
		font-size: 0.9em;
		line-height: 1.25;
	}
}

.ChallengeAction-energy {
	position: absolute;
	top: 1.6em;
	right: 0.5em;
	width: 2em;
	height: 2em;
	text-align: right;
	padding-right: 1em;
	background-image: url('../../assets/images/challenge/icon-energy-small.svg');
	background-size: auto 1.25em;
	background-position: top 0.125em right;
	background-repeat: no-repeat;
	span {
		font-size: 1.125em;
		line-height: 1;
		font-weight: bold;
		color: $text-dark;
	}
}

.ChallengeAction-moveIcon {
	display: none;
	position: absolute;
	bottom: 0.5em;
	right: 0.5em;
	width: 0.8em;
	height: 0.8em;
	text-align: right;
	padding-right: 1em;
	background-image: url('../../assets/images/challenge/icon-move.svg');
	background-size: 0.8em auto;
	background-position: center center;
	background-repeat: no-repeat;
}

.ChallengeAction--animateDrop {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
	-webkit-animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
	animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
	-webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}


.ChallengeAction.ChallengeAction--landscape {
	width: 100%;
	height: vw-calc(100);
	border-radius: vw-calc(10);
	&.ChallengeAction--selected::after {display: none;}
	.ChallengeAction-body {
		padding: vw-calc(25) vw-calc(80) vw-calc(25) vw-calc(20);
		border-radius: vw-calc(10);
		span {
			display: inline-block;
			vertical-align: top;
			font-size: vw-calc(20);
			line-height: 1.3;
		}
		
	}
	.ChallengeAction-energy {
		top: vw-calc(35);
		right: vw-calc(12);
		width: vw-calc(60);
		height: vw-calc(40);
		padding-right: vw-calc(20);
		background-size: auto vw-calc(30);
		background-position: top vw-calc(6) right;
		span {
			font-size: vw-calc(28);
			text-align: right;
		}
	}
}




@-webkit-keyframes bounce {
	0%   { transform: scale(1);}
	10%  { transform: scale(.9);}
	30%  { transform: scale(1.05);}
	60%  { transform: scale(.95);}
	100% { transform: scale(1);}
}
@keyframes bounce {
	0%   { transform: scale(1);}
	10%  { transform: scale(.9);}
	30%  { transform: scale(1.05);}
	60%  { transform: scale(.95);}
	100% { transform: scale(1);}
}

@-webkit-keyframes shake {
	0% { transform: translate(2px, 1px) rotate(0deg); }
	10% { transform: translate(-1px, -2px) rotate(-1deg); }
	20% { transform: translate(-3px, 0px) rotate(1deg); }
	30% { transform: translate(0px, 2px) rotate(0deg); }
	40% { transform: translate(1px, -1px) rotate(1deg); }
	50% { transform: translate(-1px, 2px) rotate(-1deg); }
	60% { transform: translate(-3px, 1px) rotate(0deg); }
	70% { transform: translate(2px, 1px) rotate(-1deg); }
	80% { transform: translate(-1px, -1px) rotate(1deg); }
	90% { transform: translate(2px, 2px) rotate(0deg); }
	100% { transform: translate(1px, -2px) rotate(-1deg); }
}
@keyframes shake {
	0% { transform: translate(2px, 1px) rotate(0deg); }
	10% { transform: translate(-1px, -2px) rotate(-1deg); }
	20% { transform: translate(-3px, 0px) rotate(1deg); }
	30% { transform: translate(0px, 2px) rotate(0deg); }
	40% { transform: translate(1px, -1px) rotate(1deg); }
	50% { transform: translate(-1px, 2px) rotate(-1deg); }
	60% { transform: translate(-3px, 1px) rotate(0deg); }
	70% { transform: translate(2px, 1px) rotate(-1deg); }
	80% { transform: translate(-1px, -1px) rotate(1deg); }
	90% { transform: translate(2px, 2px) rotate(0deg); }
	100% { transform: translate(1px, -2px) rotate(-1deg); }
}