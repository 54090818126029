/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.PopupIcon.PopupIcon--landscape {
	width: vw-calc(200);
	height: vw-calc(200);
	margin: 0 vw-calc(5);
	.star-outline,
	.star-filled {
		top: vw-calc(25);
		left: vw-calc(25);
		width: vw-calc(150);
		height: vw-calc(150);
	}

	.PopupIcon-animation {
		width: vw-calc(400);
		height: vw-calc(400);
		border-radius: vw-calc(400);
		top: vw-calc(-100);
		left: vw-calc(-100);
	}
	.PopupIcon-animationObject {
		font-size: vw-calc(45);
	}	
}

// 	&--star:nth-of-type(1) {
// 		.star-outline {
// 			-webkit-animation: fadeOut 0.2s #{$star1Delay - 0.1}s both;
// 			animation: fadeOut 0.2s #{$star1Delay - 0.1}s both;
// 		}
// 		.star-filled {
// 			-webkit-animation: fadeIn 0.2s #{$star1Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star1Delay}s 1 forwards;
// 			animation: fadeIn 0.2s #{$star1Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star1Delay}s 1 forwards;
// 		}
// 	}
// 	&--star:nth-of-type(2) {
// 		.star-outline {
// 			-webkit-animation: fadeOut 0.2s #{$star2Delay - 0.1}s both;
// 			animation: fadeOut 0.2s #{$star2Delay - 0.1}s both;
// 		}
// 		.star-filled {
// 			-webkit-animation: fadeIn 0.2s #{$star2Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star2Delay}s 1 forwards;
// 			animation: fadeIn 0.2s #{$star2Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star2Delay}s 1 forwards;
// 		}
// 	}
// 	&--star:nth-of-type(3) {
// 		.star-outline {
// 			-webkit-animation: fadeOut 0.2s #{$star3Delay - 0.1}s both;
// 			animation: fadeOut 0.2s #{$star3Delay - 0.1}s both;
// 		}
// 		.star-filled {
// 			-webkit-animation: fadeIn 0.2s #{$star3Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star3Delay}s 1 forwards;
// 			animation: fadeIn 0.2s #{$star3Delay - 0.1}s both, bounceStar 0.7s cubic-bezier(0.280, 0.840, 0.420, 1) #{$star3Delay}s 1 forwards;
// 		}
// 	}


// }


// .PopupIcon-animation {
// 	position: absolute;
// 	width: 10em;
// 	height: 10em;
// 	border-radius: 10em;
// 	top: -2.75em;
// 	left: -2.75em;
// 	z-index: 0;
// 	overflow: hidden;
// }
// .PopupIcon-animationObject {
// 	position: absolute;
// 	width: 0.3em;
// 	height: 0.3em;
// 	border-radius: 1em;
// 	opacity: 0;
// }

// $objects: 70;
// $maxStars: 3;
// @for $i from 1 through $objects {
// 	$angle: (random(360) * pi()) / 180.0;
// 	$length: random(5.0);
// 	$left1: (0.95 * $length * cos($angle)) + 5;
// 	$left2: ($length * cos($angle)) + 5;
// 	$top1: 5 - (0.95 * $length * sin($angle));
// 	$top2: 5 - ($length * sin($angle));
// 	@-webkit-keyframes anim-#{$i} {
// 		0% {
// 			top: 50%;
// 			left: 50%;
// 			opacity: 1;
// 		}
// 		90% {
// 			top: #{$top1}em;
// 			left: #{$left1}em;
// 			opacity: 0.9;
// 		}
// 		100%{
// 			top: #{$top2}em;
// 			left: #{$left2}em;
// 			opacity: 0;
// 		}
// 	}
// 	@keyframes anim-#{$i} {
// 		0% {
// 			top: 50%;
// 			left: 50%;
// 			opacity: 1;
// 		}
// 		90% {
// 			top: #{$top1}em;
// 			left: #{$left1}em;
// 			opacity: 0.9;
// 		}
// 		100%{
// 			top: #{$top2}em;
// 			left: #{$left2}em;
// 			opacity: 0;
// 		}
// 	}
// 	@for $j from 1 through $maxStars {
// 		.PopupIcon--star {
// 			&:nth-child(#{$j}) {
// 				$delay: $star1Delay;
// 				$color: $yellow;
// 				@if ($i > 50) { $color: white;}
// 				@if ($j == 2) { $delay: $star2Delay; }
// 				@if ($j == 3) { $delay: $star3Delay; }
// 				.PopupIcon-animationObject {
// 					&:nth-child(#{$i}){
// 						left: $i * 0.5em;
// 						-webkit-animation-duration: 0.8s;
// 						animation-duration: 0.8s;
// 						-webkit-animation-delay: #{$delay}s;
// 						animation-delay: #{$delay}s;
// 						-webkit-animation-name: anim-#{$i};
// 						animation-name: anim-#{$i};
// 						-webkit-animation-iteration-count: 1;
// 						animation-iteration-count: 1;
// 						-webkit-animation-direction: alternate;
// 						animation-direction: alternate;
// 						-webkit-animation-fill-mode: forwards;
// 						animation-fill-mode: forwards;
// 						-webkit-animation-timing-function: ease-out;
// 						animation-timing-function: ease-out;
// 						background: $color
// 						}
// 					}
// 				}
// 			}
// 		}
// }
