/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.AvatarInfo {
	position: relative;
	height: 100%;
	padding: 0em 2em;
	color: $text-dark;
	z-index: 1;
	background-image: url('../../assets/images/background-stairs.jpg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	@include flex('center','center');
	&--challenge,
	&--goals,
	&--part2 {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		z-index: 10;
	}

}

.AvatarInfo-card {
	position: relative;
	width: 100%;
	padding-top: 2.8em;
}

.AvatarInfo-body {
	background-color: #F0F0F0;
	padding: 2.5em 0.825em 1em 0.825em;
	border-radius: 0.625em;
}

.AvatarInfo-name {
	text-align: center;
	font-weight: bold;
	padding-bottom: 0.5em;
	span {font-weight: 100;}
}

.AvatarInfo-intro {
	text-align: left;
	border-top: 0.125em solid rgba(#61A4D8, 0.4);
	padding: 0.5em 0.25em 0.25em;
	margin-bottom: 0.5em;
	font-weight: normal;
	span {
		font-size: 0.9375em;
		font-weight: normal;
	}
}


.AvatarInfo-stats {
	margin-bottom: 1.5em;
}

.AvatarInfo-stat {
	position: relative;
	width: 100%;
	height: 2.875em;
	line-height: 2.875em;
	text-align: left;
	padding-left: 3em;
	border-top: 0.05em solid rgba(#707070, 0.3);
	background-size: auto 1.4em;
	background-repeat: no-repeat;
	background-position: left 0.5em center;
	&:nth-last-child(1) {border-bottom: 0.05em solid rgba(#707070, 0.3);}
	&--danish {background-image: url('../../assets/images/stats/danish-transparent.svg');}
	&--reading {background-image: url('../../assets/images/stats/reading-transparent.svg');}
	&--math {background-image: url('../../assets/images/stats/math-transparent.svg');}
	&--teamwork {background-image: url('../../assets/images/stats/teamwork-transparent.svg');}
	&--workEthic {background-image: url('../../assets/images/stats/workEthic-transparent.svg');}
	span {
		display: inline-block;
		position: absolute;
		right: 1em;
		font-weight: bold;
	}
}

.AvatarInfo-description {
	text-align: left;
	padding: 0em 0.25em;
	margin-bottom: 0.5em;
	span {
		font-size: 0.9375em;
		line-height: 1.15;
	}
}

.AvatarInfo-okBtn {
	margin: auto;
}