/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.CookieConsent {
	position: fixed;
	width: 22.5em;
	width: 100%;
	bottom: 0;
	color: $text-dark;
	background: white;
	height: 0;
	-webkit-animation: slideIn 0.5s linear 0s 1 forwards;
	animation: slideIn 0.5s linear 0s 1 forwards;
}

.CookieConsent-text {
	padding: 0 1em;
	span {
		text-decoration: underline;
		cursor: pointer;
	}
}

.CookieConsent-button {
	display: inline-block;
}


@-webkit-keyframes slideIn {
	0% {height: 0;}
	100% {height: 10em;}
}
@keyframes slideIn {
	0% {height: 0;}
	100% {height: 10em;}
}

@-webkit-keyframes slideInLandscape {
	0% {height: 0;}
	100% {height: vw-calc(130);}
}
@keyframes slideInLandscape {
	0% {height: 0;}
	100% {height: vw-calc(130);}
}


.CookieConsent.CookieConsent--landscape {
	width: calc(22.5em - 2px);
	-webkit-animation: slideInLandscape 0.5s linear 0s 1 forwards;
	animation: slideInLandscape 0.5s linear 0s 1 forwards;
	padding-bottom: 0;
	.CookieConsent-text {
		padding: vw-calc(20) vw-calc(400) vw-calc(20) vw-calc(40);
		text-align: left;
		p {
			margin: 0;
			font-size: vw-calc(30);
		}
	}
	.CookieConsent-button {
		position: absolute;
		right: vw-calc(40);
		top: 0;
		padding-top: vw-calc(35);
		vertical-align: top;
	}
}