/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Diploma.Diploma--landscape {
	padding: 0;
	overflow: scroll;

	.Diploma-body {
		border-style: none;
		padding: vw-calc(60) vw-calc(35);
	}

	.Diploma-buttons {
		position: absolute;
		top: vw-calc(60);
		right: vw-calc(35);
		text-align: right;
	}

	.Diploma-medal {
		position: absolute;
		top: vw-calc(50);
		left: vw-calc(95);
		width: vw-calc(275);
		height: vw-calc(275);
		background-position: top left;
	}

	.Diploma-title {
		position: relative;
		top: auto;
		right: auto;
		width: vw-calc(400);
		margin: vw-calc(30) auto 0 auto;
		font-size: 1em;
		text-align: center;
		padding-bottom: vw-calc(20);
		border-bottom: vw-calc(4) solid rgba(#4B6E72, 0.2);
		margin-bottom: vw-calc(80);
		span {
			font-size: vw-calc(50);
			text-transform: uppercase;
		}
	}	

	.Diploma-section {
		padding: 0;
		position: relative;
		width: vw-calc(1160);
		margin: auto;
	}

	.Diploma-line {
		width: vw-calc(1160);
		margin: vw-calc(50) auto;
		height: 0;
		border-bottom: vw-calc(4) solid rgba(#4B6E72, 0.2);
	}
	
	.Diploma-name,
	.Diploma-score,
	.Diploma-nextStep,
	.Diploma-answers {
		display: block;
		font-size: vw-calc(35);
		font-weight: 700;
		margin-bottom: 0.3em;
	}
	.Diploma-name {span {font-weight: normal;}}


	.AvatarStats-stats.AvatarStats-stats--diploma {
		position: relative;
		left: auto;
		right: auto;
		height: auto;
		bottom: auto;
		border:none;
		width: vw-calc(1200);
		margin: auto;
		padding-right: vw-calc(450);
		@include flex("space-between", "center", "row");

		.AvatarStats-stat {
			width: vw-calc(150);
			border-style: none;
		}
	}

	.Diploma-interest-heading,
	.Diploma-education-heading,
	.Diploma-experience-heading {
		font-size: vw-calc(35);
		margin-top: 0.5em;
		font-weight: 700;
	}

	.Diploma-interest-value,
	.Diploma-education-value,
	.Diploma-experience-value {
		font-size: vw-calc(35);
	}

	.Diploma-action {
		font-size: vw-calc(35);
		margin-top: 0.4em;
		.Diploma-action-index {font-size: 1em;}
	}

	.Diploma-table {
		width: 100%;
		border-collapse: collapse;
		
		font-size: vw-calc(25);
		margin-bottom: 1.5em;
		th:first-child,
		td:first-child {
			width: 15em;
			min-width: 15em;
			max-width: 15em;
			padding: 0.3em 0.5em;
		}
	}
}

@media print {
	.Diploma.Diploma--landscape {
		height: auto;
		overflow: auto;
		padding: 0;
		font-size: calc(16 * ((100vw / 360)));
		.Diploma-body {
			position: relative;
			border-style: none;
			padding: 30px 20px;
		}
		.Diploma-buttons {display: none;}
	}
}