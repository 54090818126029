/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.AvatarStats {
	position: relative;
	width: 100%;
	height: 10.25em;
	padding: 2.5em 0.8em 0.5em 0.8em;
	color: $text-dark;
	background-color: white;
	background: linear-gradient(#F0F0F0, white, white 50%);
	@include box-shadow-custom(0, 0.1875em, 0.375em, 0, rgba(0, 0, 0, 0.16));
	&--landscape {
		display:inline-block;
		vertical-align: top;
		width: 100%;
		height: vw-calc(170);
		padding: 0;
		background-color: transparent;
		background-image: none;
		@include box-shadow-custom(0, 0, 0, 0, rgba(0, 0, 0, 0));
	}
}

.AvatarStats-nameAndAge {
	margin-left: 7.5em;
	text-align: left;
	.AvatarStats-name {
		font-size: 1.25em;
		font-weight: bold;
	}
	.AvatarStats-age {
		margin-left: 0.5em;
		font-size: 0.85em;
		width: 3.5em;
	}
}

.Goals,
.Challenge {
	.AvatarStats-nameAndAge {
		@include flex("center", "flex-start", "column");
	}
}

.AvatarStats-energy {
	position: absolute;
	top: 1.8em;
	right: 1.375em;
	span {
		font-size: 1.25em; 
		font-weight: bold; 
		line-height: 1em;
	}
	width: 4.75em;
	height: 4.75em;
	background-color: transparent;
	color: #516B6F;
	padding-top: 2.5em;
	text-align: right;
	padding-right: 0.8em;
	background-image: url('../../assets/images/icon-energy.svg');
	background-size: auto 3.375em;
	background-position: left 1em top 0.5625em;
	background-repeat: no-repeat;
	@include no-select();
	.CircularProgress {
		position: absolute;
		top: -0.6em;
		left: -0.6em;
	}
	&.negative span {color: $red;}

	&--landscape {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		top: 0;
		right: 0;
		width: vw-calc(110);
		height: vw-calc(110);
		padding-top: vw-calc(60);
		padding-right: vw-calc(28);
		background-size: auto vw-calc(65);
		background-position: left vw-calc(30) top vw-calc(20);
		span {
			display: inline-block;
			vertical-align: top;
			font-size: vw-calc(25);
		}
		.CircularProgress {
			top: 0;
			left: 0em;
		}

	}
}

.AvatarStats-energyLabel {
	height: 100%;
	display: inline-block;
	vertical-align: middle;
	@include no-select();
	span {
		font-size: vw-calc(20);
	}
}

.AvatarStats-popup {
	position: absolute;
	top: 10.75em;
	left: 1em;
	// width: 20.5em;
	width: calc(100% - 2em);
	border-radius: 0.625em;
	background: linear-gradient(#C6E7FF 30%, #7CC6FF);
	color: $text-dark;
	text-align: left;
	padding: 1em;
	&::after {
		content: "";
		position: absolute;
		top: -1em;
		left: calc(50% - 1em);
		height: 0;
		width: 0;
		border-bottom: 1.25em solid #C6E7FF;
		border-left: 1em solid transparent;
		border-right: 1em solid transparent;
	}
	&--teamwork::after{left: 0.7em;}
	&--danish::after{left: 4.9em;}
	&--reading::after{left: 9em;}
	&--math::after{left: 13.1em;}
	&--workEthic::after{left: 17.25em;}
	&--goals {
		z-index: 10;
	}
}
.AvatarStats-popupTitle {
	font-weight: 700;
	background-size: auto 100%;
	background-position: left center;
	background-repeat: no-repeat;
	padding-left: 2em;
	&--teamwork {background-image: url('../../assets/images/stats/teamwork.svg');}
	&--danish {background-image: url('../../assets/images/stats/danish.svg');}
	&--reading {background-image: url('../../assets/images/stats/reading.svg');}
	&--math {background-image: url('../../assets/images/stats/math.svg');}
	&--workEthic {background-image: url('../../assets/images/stats/workEthic.svg');}
}


.AvatarStats-popup.AvatarStats-popup--landscape {
	font-size: vw-calc(20);
	background-color: #F1F9FF;
	background-image: none;
	border-radius: 0;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	transition: height 0.15s linear, left 0s 0.15s linear;
	overflow: hidden;
	top: 8.5em;
	left: 21.25em;
	width: 21em;
	height: 0;
	padding: 0;
	&::after {display: none;}
	&.AvatarStats-popup--show {
		height: 8em;
		transition: height 0.25s linear;
		@include box-shadow-custom(0, 0.15em, 0.3em, 0, rgba(#000, .16)); 
		
	}
	&.AvatarStats-popup--danish {left: 27.8em;}
	&.AvatarStats-popup--reading {left: 34.35em;}
	&.AvatarStats-popup--math {left: 40.9em;}
	&.AvatarStats-popup--workEthic {left: 47.45em;}
	.AvatarStats-popupText {
		padding: 1em 2em;
		span {font-weight: 700;}
	}
}