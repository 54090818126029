/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Scrollbar {
	position: absolute;
	width: 2.5em;
	height: 10em;
	pointer-events: none;
	z-index: 4;

	&--actions {
		top: 9em;
		left: 0;
		.Scrollbar-thumbContent {
			border-top-right-radius: 4.5em;
			border-bottom-right-radius: 4.5em;		
		}
	}
	&--selected {
		top: 9em;
		right: 0;
		.Scrollbar-thumbContent {
			border-top-left-radius: 4.5em;
			border-bottom-left-radius: 4.5em;		
			background-position: top 0.8em right 0.5em, bottom 0.8em right 0.5em;
		}		
	}
}


.Scrollbar-track {
	position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
	pointer-events: none;
}

.Scrollbar-thumb {
  width: 100%;
  height: 4.5em;
	pointer-events: all;
  cursor: pointer;
}

.Scrollbar-thumbContent {
	margin-top: 0.45em;
  width: 2.5em;
  height: 4em;
	background-color: rgba(#ffffff, 0.5);
	background-image: url('../../assets/images/icon-arrow-up-white.svg'), url('../../assets/images/icon-arrow-down-white.svg');
	background-size: 1.2em auto, 1.2em auto;
	background-position: top 0.8em left 0.5em, bottom 0.8em left 0.5em;
	background-repeat: no-repeat, no-repeat;
	@include box-shadow-custom(0, 0.125em, 0.25em, 0, #000);
}


.Scrollbar.Scrollbar--landscape {
	height: vw-calc(820);
	width: vw-calc(15);
	border-radius: vw-calc(10);
	cursor: pointer;
	&.Scrollbar--selected {
		top: auto;
		bottom: vw-calc(15);
		right: vw-calc(15);
	}
	.Scrollbar-track {
		background: rgba(#FFFFFF, 0.4);
		border-radius: vw-calc(10);
		pointer-events: all;
		overflow: hidden;
	}
	.Scrollbar-thumb { 
		height: vw-calc(240);
	}
	.Scrollbar-thumbContent {
		margin: 0;
		width: 100%;
		height: 100%;
		background-color: #9AC1E0;
		background-image: none;
		border-radius: vw-calc(10);
	}

}