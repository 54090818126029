/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Goals {
	width: 100%;
	height: 100%;
	min-height: 100%;
	color: $text-dark;
	background-image: url('../../assets/images/background-stairs.jpg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	&--portrait .AvatarStats-stats {
		text-align: left;
	}
}

.Goals-carousel {
	position: absolute;
	top: 10.25em;
	width: 100%;
	height: calc(100% - 10.25em);
}

.Goals-avatarBonusInfo {
	position: absolute;
	left: vw-calc(45);
	top: vw-calc(170);
	width: vw-calc(520);
	height: 0;
	background-color: #F1F9FF;
	border-bottom-left-radius: vw-calc(10);
	border-bottom-right-radius: vw-calc(10);
	transition: height 0.25s linear;
	overflow: hidden;
	span {
		display: inline-block;
		vertical-align: top;
		font-size: vw-calc(20);
		line-height: 1.25;
		padding: 1.25em;
	}
	&.show {
		height: vw-calc(150);
		@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
	}
}


.Goals.Goals--landscape {
	position: relative;
	background-color: #D5ECFE;
	background-image: none;
	.Goals-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: vw-calc(170);
		background-color: rgba(white, 0.5);
		text-align: left;
		z-index: 2;
		@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
	}
	.Goals-headerAvatarInfo {
		text-align: left;
		display: inline-block;
		vertical-align: top;
		width: vw-calc(610);
		height: vw-calc(170);
		background-image: linear-gradient(#A4D0F1, #BADFFA);
		padding: vw-calc(20) vw-calc(20) vw-calc(20) vw-calc(225);
		cursor: pointer;
	}
	.Goals-headerAvatarInfoText {
		font-size: vw-calc(20);
		span {
			display: inline-block;
			vertical-align: top;
			font-size: 1.5em;
			font-weight: bold;
		}
	}

	.Goals-headerAvatarStats {
		display: inline-block;
		vertical-align: top;
		width: vw-calc(655);
		height: vw-calc(170);
	}

	.Goals-carousel {
		position: relative;
		top: 0;
		width: 100%;
		height: 100%;
		padding-top: vw-calc(120);
		background-image: none;
		display: block;
	}

	.CarouselLandscape-card {
		height: vw-calc(830);
	}
}