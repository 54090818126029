/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Select {
	position: relative;
	display: inline-block;
	width: 17em;
	&.open {
		.Select-dropdown {
			height: auto;
			border: 0.053em solid $light-blue;
		}
		.Select-button {
			top: 0.8em;
			background-image: url('../../assets/images/icon-arrow-up.svg');
		}
	}
	
	.Select-panel {
		height: 2em;
		&--null .Select-selected{
			color: $placeholder-grey;
		}
	}
	
	.Select-selected {
		display: inline-block;
		width: 100%;
		height: 2.4em;
		background-color: #fff;
		color: $text-dark;
		font-weight: 500;
		font-size: 1em;
		padding-left: 0.9em;
		padding-top: 0.4em;
		border-radius: 0.421em;
		text-align: left;
		border: 0.053em solid $light-blue;
	}
	.Select-button {
		position: absolute;
		top: 0.9em;
		right: 0.4em;
		width: 0.8em;
		height: 0.8em;
		background-image: url('../../assets/images/icon-arrow-down.svg');
		background-position: center right;
		background-size: 100%;
		background-repeat: no-repeat;
	}

	.Select-dropdown {
		position: absolute;
		left: 0;
		top: 2.25em;
		width: 100%;
		height: 0;
		background-color: white;
		overflow: hidden;
		z-index: 2;
		border-bottom-left-radius: 0.421em;
		border-bottom-right-radius: 0.421em;
		@include transition('height', 0.3s, 'ease');
		
		.Select-option {
			cursor: pointer;
			position: relative;
			color: $text-dark;
			text-align: left;
			border-top: 0.1em solid $grey;
			padding: 0.5em 0 0.5em 0.9em;
		}
	}
}


.Select.Select--landscape {
	width: 100%;
	vertical-align: top;
	&.open {
		.Select-dropdown {
			border: vw-calc(1) solid $light-blue;
			border-top: none;
		}
		.Select-button {
			top: vw-calc(0);
			background-image: url('../../assets/images/icon-arrow-up-white2.svg');
		}
	}
	
	.Select-panel {
		vertical-align: top;
		height: vw-calc(70);
		&--null .Select-selected{
			color: white;
		}
	}
	
	.Select-selected {
		height: vw-calc(70);
		color: white;
		background-color: #A4D0F1;
		padding-left: vw-calc(20);
		padding-top: 0;
		border: vw-calc(1) solid $light-blue;
		border-radius: vw-calc(8);
		span {
			display: inline-block;
			vertical-align: top;
			height: 2.8em;
			font-size: vw-calc(25);
			line-height: 2.8;
		}
	}
	.Select-option {
		height: vw-calc(50);
		span {
			display: inline-block;
			vertical-align: top;
			font-size: vw-calc(25);
			line-height: 2;
			padding: 0 0 0 1em;
		}
		&:hover {
			background-color: darken(#A4D0F1, 5%);
		}
	}
	.Select-button {
		position: absolute;
		top: 0;
		right: 0;
		width: vw-calc(70);
		height:vw-calc(70);
		background-image: url('../../assets/images/icon-arrow-down-white2.svg');
		background-position: top vw-calc(30) right vw-calc(25);
		background-size: vw-calc(25) auto;
		background-repeat: no-repeat;
	}

	.Select-dropdown {
		position: absolute;
		left: 0;
		top: vw-calc(70);
		background-color: #A4D0F1;
		border-bottom-left-radius: vw-calc(8);
		border-bottom-right-radius: vw-calc(8);
		@include transition('height', 0.3s, 'ease');
		
		.Select-option {
			color: white;
			border-top: vw-calc(1) solid $light-blue;
			padding: 0; //0.5em 0 0.5em 0.9em;
		}
	}
}