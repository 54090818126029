/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.CarouselLandscape {
	position: relative;
	height: 100%;
	width: 100%;
	// &--avatars {
	// 	.Carousel-cardWrap {
	// 		top: 0;
	// 		left: 3.125em;
	// 		width: 16.25em;
	// 	}
	// 	.Carousel-card {
	// 		width: 14em;
	// 		height: 28em;
	// 		padding: 1em 0.5em;
	// 		margin: 0;
	// 	}
	// }

	// &--interests {
	// 	.Carousel-card {
	// 		height: 24.313em;
	// 	}
	// }
}

.CarouselLandscape-cardWrap {
	position: absolute;
	left: vw-calc(690);
	width: vw-calc(540);
	height: 100%;
	padding: 0;
	padding-top: vw-calc(90);
	
	will-change: transform;
	z-index: 1;
	@include flex('center','center');
}

.CarouselLandscape-card {
	position: relative;
	text-align: left;
	width: vw-calc(480);
	height: vw-calc(880);
	border-radius: vw-calc(5);
	color: $text-dark;
	will-change: transform;
}


.CarouselLandscape-prevBtn,
.CarouselLandscape-nextBtn {
	position: absolute;	
	top: vw-calc(492);
	width: vw-calc(95);
	height: vw-calc(95);
	z-index: 2;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.CarouselLandscape-prevBtn {
	left: vw-calc(70);
	background-image: url('../../assets/images/icon-prev.svg');
}
.CarouselLandscape-nextBtn {
	right: vw-calc(70);
	background-image: url('../../assets/images/icon-next.svg');
}

// @media screen and (max-height: 668px) {
// 	.Carousel-card {
// 		height: 26.2em;
// 	}
	
// }