/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Popup-overlay.Popup-overlay--landscape {
	.Popup {
		width: vw-calc(1000);
		border-radius: vw-calc(10);
		@include box-shadow-custom(0, vw-calc(3), vw-calc(3), 0, rgba(black, 0.16));
	}
	.Popup-body {
		padding: vw-calc(15) vw-calc(30);
	}	
	.Popup-title {
		span {
			display: block;
			font-size: vw-calc(30); 
			line-height: 1.25;
		}
	}
	.Popup-text {
		margin: vw-calc(15) 0;
		span {
			display: block;
			font-size: vw-calc(25); 
			line-height: 1.25;
		}
	}
	.Popup-buttons {
		margin-top: vw-calc(15);
		height: vw-calc(60);
		&--2 {
			.Button {margin: 0 vw-calc(10);}
		}
	}

	.Popup-closeBtn {
		right: vw-calc(10);
		top: vw-calc(10);
		width: vw-calc(20);
		height: vw-calc(20);
		cursor: pointer;
	}

	/* Intro popups */
	.Popup--introPopup {
		width: vw-calc(550);
		.Popup-body {padding: vw-calc(30);}
		.Popup-title,
		.Popup-text,
		.Popup-buttons {
			span {
				font-size: vw-calc(25);	
			}
		}
		.Popup-text {margin-bottom: vw-calc(50)}
		.Popup-closeBtn {
			width: vw-calc(15);
			height: vw-calc(15);
		}
	}

	/* Part 1: challenge start warning popups */
	.Popup--noActionsAvailable,
	.Popup--redGoal,
	.Popup--redActions,
	.Popup--notEnoughEnergy,
	.Popup--notEnoughEnergyAndRedActions,
	.Popup--start-challenge-confirm {
		width: vw-calc(660);
		background: linear-gradient(#F2F2F2, #DBDBDB);
		.Popup-body {padding: vw-calc(50);}	
		.Popup-text {
			text-align: left;
			background-size: vw-calc(500) auto;
			padding-bottom: 0;
		}
		.Popup-buttons--2 .Button {width: vw-calc(250);}
	}
	
	.Popup--noActionsAvailable .Popup-text {
		padding-bottom: vw-calc(250);
		background-image: url('../../assets/images/popup-challenge-noActionsAvailable2.png');
	}
	.Popup--redGoal .Popup-text {
		padding-bottom: vw-calc(215);
		margin-bottom: vw-calc(35);
		background-image: url('../../assets/images/popup-challenge-redGoal2.png');
	}
	.Popup--redActions,
	.Popup--notEnoughEnergy,
	.Popup--notEnoughEnergyAndRedActions {
		background: linear-gradient(#FC676C, #DE464A);
		.Popup-text,
		.Popup-title {color: white;}
	}

	.Popup--start-challenge-confirm { 
		background: linear-gradient( #7CC6FF, #C6E7FF);
		.Popup-text {
			padding: vw-calc(10) 0 vw-calc(40) 0;
		}
		.Popup-buttons--2 .Button {width: vw-calc(200); margin: 0 vw-calc(30)}
	}

	/* Part 1: challenge failed popup */
	.Popup--challenge-failed {
		.Popup-body {padding: vw-calc(20) vw-calc(30) vw-calc(30) vw-calc(30);}
	}

	/* Part 1: challenge completed popup */
	.Popup--challenge-completed {
		width: vw-calc(1000);
		.Popup-title span {font-size: vw-calc(30)}
		.Popup-body {padding: vw-calc(15) vw-calc(20);}
		.Popup-buttons {padding-bottom: vw-calc(15);}
		.Popup-icons {margin-bottom: vw-calc(30);}
	}
	.Popup-fireworks {
		font-size: vw-calc(45);
	}

	/* Part 2: evaluate actions popup */
	.Popup--redActions,
	.Popup--yellowActions,
	.Popup--greenActions {
		width: vw-calc(660);
		margin-bottom: vw-calc(150);
		.Popup-body {padding: vw-calc(50);}	
		.Popup-text {padding: 0;}
		.Popup-buttons--2 .Button {width: vw-calc(250);}
	};

	/* Part 2: email popups */
	.Popup--game-result-saved,
	.Popup--send-email,
	.Popup--close-send-email,
	.Popup--email-successful,
	.Popup--email-failed {
		margin-bottom: vw-calc(80);
		p {margin: vw-calc(8) 0;}
	}

	.Popup--send-email {
		p:nth-of-type(2) {
			margin-bottom: vw-calc(15);
		}
		.Popup-input {
			Input {
				vertical-align: top;
				font-size: vw-calc(25);
			}
		}
		.Popup-closeBtn {
			width: vw-calc(30);
			height: vw-calc(30);
		}
	}
	.Popup--game-result-saved,
	.Popup--close-send-email,
	.Popup--email-successful,
	.Popup--email-failed {
		.Popup-body {
			padding: vw-calc(50);
		}
	}
}