/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.InterestCard {
	width: 100%;
	height: 100%;
}

.InterestCard-title {
	position: relative;
	height: 3.25em;
	font-size: 1.25em;
	font-weight: bold;
	line-height: 1;
	text-align: center;
}

.InterestCard-text {
	font-weight: normal;
	padding-top: 1em;
	margin-bottom: 1em;
}

.InterestCard-selectCard {
	margin-top: 2.5em;
	text-align: center;
}

.InterestCard-form {
	@include input-placeholder($placeholder-grey);
}

.InterestCard-input {
	width: 12.5em;
	height: 3.125em;
	padding-left: 0.7em;
	border: solid 0.063em #D9D9D9;
	border-radius: 0.625em;
	font-size: 1em;
	background-image: linear-gradient(#fff, #EAEAEA);
}

.InterestCard-line {
	position: relative;
	top: 1em;
	margin: 0 auto;
	border-top: solid 0.056em #707070;

}

.InterestCard-image {	
	margin: 0 auto;
	width: 5.2em;
	height: 5.2em;
	border-radius: 4em;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url('../../assets/images/goals/default.svg');
	
	&--food_health {background-image: url('../../assets/images/goals/food_health.svg');}
	&--office_commerce {background-image: url('../../assets/images/goals/office_commerce.svg');}
	&--caregiving {background-image: url('../../assets/images/goals/caregiving.svg');}
	&--design_media {background-image: url('../../assets/images/goals/design_media.svg');}
	&--animals_nature_environment {background-image: url('../../assets/images/goals/animals_nature_environment.svg');}
	&--crafts_construction {background-image: url('../../assets/images/goals/crafts_construction.svg');}
	&--it_electronics {background-image: url('../../assets/images/goals/it_electronics.svg');}
	&--engineering_mechanics_machines {background-image: url('../../assets/images/goals/engineering_mechanics_machines.svg');}
	&--transport_logistics {background-image: url('../../assets/images/goals/transport_logistics.svg');}

}


.InterestCard.InterestCard--landscape {
	height: vw-calc(800);
	padding: vw-calc(35) vw-calc(45);
	background-color: #F1F9FF;
	@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(0, 0, 0, 0.16));

	.InterestCard-title {
		font-size: vw-calc(35);
		height: 2em;
	}

	.InterestCard-text {
		font-size: vw-calc(30);
		padding-top: 0.5em;
		margin-bottom: 0.5em;
		margin-top: 1em;
	}

	.InterestCard-selectCard {
		position: absolute;
		width: 100%;
		height: vw-calc(65);
		bottom: vw-calc(120);
		left: 0;		
	}

	.InterestCard-form {
		@include input-placeholder(white);
	}

	.InterestCard-input {
		width: 100%;
		font-size: vw-calc(30);
		height: 2.5em;
		line-height: 2.5em;
		background-color: #A4D0F1;
		border: vw-calc(1) solid #BCE0FD;
		border-radius: 0.3em;
		color: white;
		vertical-align: top;
		padding-left: 0.5em;
		background-image: none;
	}

	.InterestCard-line {
		top: vw-calc(30);
		border-top: solid 1px #707070;

	}

	.InterestCard-image {	
		width: vw-calc(200);
		height: vw-calc(200);
		border-radius: vw-calc(200);
	}
}