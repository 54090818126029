/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Avatars {
	height: 100%;
}

.Avatars-carousel {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	color: $text-dark;
	z-index: 1;
	background-image: url('../../assets/images/background-stairs.jpg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	@include flex('center','center');
}

.Avatars.Avatars--landscape {
	position: relative;
	background-color: #D5ECFE;
	.Avatars-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: vw-calc(95);
		background-color: rgba(white, 0.5);
		text-align: center;
		z-index: 2;
		@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
	}
	.Avatars-headerTitle {
		font-size: vw-calc(35);
		line-height: 2.7;
		font-weight: 700;
		color: $text-dark;
	}
	.Avatars-carousel {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: none;
		display: block;
	}
}


// @media screen and (min-width: 512px) {
// 	.Avatars {
// 		right: auto;
// 		left: auto;
// 		width: 22.5em;
// 	}
// }