/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.ChallengeHeader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: vw-calc(170);
	background-color: rgba(white, 0.5);
	text-align: left;
	z-index: 2;
	@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
}

.ChallengeHeader-avatarInfo {
	text-align: left;
	display: inline-block;
	vertical-align: top;
	width: vw-calc(610);
	height: vw-calc(170);
	background-image: linear-gradient(#A4D0F1, #BADFFA);
	padding: vw-calc(20) vw-calc(20) vw-calc(20) vw-calc(225);
	cursor: pointer;
}
.ChallengeHeader-avatarInfoText {
	font-size: vw-calc(20);
	span {
		display: inline-block;
		vertical-align: top;
		font-size: 1.5em;
		font-weight: bold;
	}
}

.ChallengeHeader-avatarBonusInfo {
	position: absolute;
	left: vw-calc(45);
	top: vw-calc(170);
	width: vw-calc(520);
	height: 0;
	background-color: #F1F9FF;
	border-bottom-left-radius: vw-calc(10);
	border-bottom-right-radius: vw-calc(10);
	transition: height 0.25s linear;
	overflow: hidden;
	// z-index: 3;
	// @include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
	span {
		display: inline-block;
		vertical-align: top;
		font-size: vw-calc(20);
		line-height: 1.25;
		padding: 1.25em;
	}
	&.show {
		height: vw-calc(150);
		@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
	}
}

.ChallengeHeader-avatarStats {
	display: inline-block;
	vertical-align: top;
	width: vw-calc(860);
	height: vw-calc(170);
}

.ChallengeHeader-goal {
	position: absolute;
	top: 0;
	right: 0;
	width: vw-calc(450);
	height: vw-calc(225);
	z-index: 2;
	background-image: linear-gradient(#FE7578, #F15156); 
	border-bottom-left-radius: vw-calc(10);
	@include no-select();
	&.green,
	&.finished{
		background-image: none;
		background-color: $green;
	}
	&.finished {
		-webkit-animation-name: bounce;
		animation-name: bounce;
		-webkit-animation-duration: .7s;
		animation-duration: .7s;
		-webkit-animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
		animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
	}
	&::after {
		content: "";
		position: absolute;
		top: vw-calc(20);
		right: vw-calc(20);
		width: vw-calc(80);
		height: vw-calc(80);
		background-image: url('../../assets/images/challenge/icon-flag.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}
}
.ChallengeHeader-goalTitle {
	color: white;	
	padding: vw-calc(20) vw-calc(20) vw-calc(20) vw-calc(60);
	span {
		display: block;
		font-size: vw-calc(25);
		line-height: 1;
		&:nth-child(2) {
			font-size: vw-calc(30);
			font-weight: bold;
		}
	}	
}

.ChallengeHeader-goalRequiredActions {
	position: relative;
	padding-left: vw-calc(60);
}
.ChallengeHeader-goalRequiredAction {
	position: relative;
	height: 0.5em;
	padding-left: 0.5em;
	span {
		display: inline-block;
		vertical-align: top;
		font-size: vw-calc(20);
		line-height: 1.5em;
		color: white;
		text-align: left;
		font-weight: bold;	
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0.075em;
		width: 0.25em;
		height: 0.25em;
		background-color: white;
		background-image: url('../../assets/images/icon-checked-false.svg');
		background-position: center center;
		background-size: 85% auto;
		background-repeat: no-repeat;

	}
	&.selected {
		&::before {
			background-image: url('../../assets/images/icon-checked-true.svg');
		}
	}
}

