/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.TutorialIcon {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	width: 1.625em;
	height: 1.625em;
	font-weight: 700;
	background-image: url('../../assets/images/icon-question.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	z-index: 10;
	// &--challenge {
	// 	position: fixed;
	// }
}
