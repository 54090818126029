// APPEARANCE
@mixin appearance($value) {
	-webkit-appearance: #{$value};
	-moz-appearance:    #{$value};
	appearance:         #{$value};
}

// TOUCH ACTION
@mixin touchaction($value) {
	touch-action: #{$value};
	-ms-touch-action: #{$value};
}

// FLEX
@mixin flex($justify:"", $align:"", $direction:"") {
	display: -webkit-box;      // OLD - iOS 6-, Safari 3.1-6
	display: -moz-box;         // OLD - Firefox 19- (buggy but mostly works)
	display: -ms-flexbox;      // TWEENER - IE 10
	display: -webkit-flex;     // NEW - Chrome
	display: flex;             // NEW, Spec - Opera 12.1, Firefox 20+
	@include flex-properties($justify, $align, $direction);
}

@mixin inline-flex($justify:"", $align:"", $direction:"") {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
  @include flex-properties($justify, $align, $direction);
}

@mixin flex-properties($justify, $align, $direction) {
	@if $justify !="" {
		-webkit-justify-content: #{$justify};
		justify-content: #{$justify};
		-webkit-box-pack: #{$justify};
		-ms-flex-pack: #{$justify};
	}
	@if $align !="" {
		-webkit-box-align: #{$align};
		-webkit-flex-align: #{$align};
		-ms-flex-align: #{$align};
		-webkit-align-items: #{$align};
		align-items: #{$align};	
	}
	@if $direction !="" {
		-webkit-box-direction: #{$direction};
		-webkit-flex-direction: #{$direction};
		flex-direction: #{$direction};
	}
}

@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap; /* Safari 6.1+ */
	flex-wrap: $wrap;
}

@mixin align-content($align) {
	-webkit-align-content: $align;
    align-content: $align;
}

@mixin align-self($value) {
	-webkit-align-self: #{$value};
	align-self: #{$value};
}

// SCALE
@mixin scale($scale) {
	-ms-transform: scale($scale); /* IE 9 */
	-webkit-transform: scale($scale); /* Safari */
	transform: scale($scale);
}

// ROTATE
@mixin rotate($angle) {
	-ms-transform: rotate($angle); /* IE 9 */
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle);
}

// TRANSITION
@mixin transition($target,$seconds,$type) {	
	-moz-transition: #{$target} $seconds #{$type};		/* Firefox */
	-webkit-transition: #{$target} $seconds #{$type};	/* WebKit */
	-o-transition: #{$target} $seconds #{$type};			/* Opera */
	transition: #{$target} $seconds #{$type};					/* Standard */
}

@mixin translate($x, $y: 0) {
	-ms-transform: translate($x, $y);
	-webkit-transform: translate($x, $y);
	transform: translate($x, $y);
}

@mixin origin($x, $y) {
	-webkit-transform-origin: $x $y;
	transform-origin: $x $y;
}

// OPACITY
@mixin opacity($opacity) {
	$procentOpacity: $opacity * 100;	
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="$procentOpacity")"; /* IE 8 */
	filter: alpha(opacity=$procentOpacity); /* IE 5-7 */
	-moz-opacity: $opacity; /* Netscape */
	-khtml-opacity: $opacity; /* Safari 1.x */
	opacity: $opacity;
}

// INPUT
@mixin input-placeholder($color) {
	input::-webkit-input-placeholder {color: $color !important;}	/* Chrome/Opera/Safari */
	input:-moz-placeholder {color: $color !important;}  					/* Firefox 18- */
	input::-moz-placeholder {color: $color !important;} 					/* Firefox 19+ */
	input:-ms-input-placeholder {color: $color !important;}			/* IE 10+ */
}

// TEXTAREA
@mixin textarea-placeholder($color) {
	textarea::-webkit-input-placeholder {color: $color !important;}	/* Chrome/Opera/Safari */
	textarea:-moz-placeholder {color: $color !important;}  					/* Firefox 18- */
	textarea::-moz-placeholder {color: $color !important;} 					/* Firefox 19+ */
	textarea:-ms-input-placeholder {color: $color !important;}			/* IE 10+ */
}

// NO-SELECT
@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/
}

// SCROLLBARS
/* HIde scrollbar */
@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	&::-webkit-scrollbar {display: none;}
}


/* FANCY SCROLLBAR Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
@mixin fancy-scrollbar($width,$height,$backgroundColor,$borderRadius,$thumbWidth, $thumbHeight, $thumbColor) { 
	::-webkit-scrollbar {
		width: $width;
		height: $height;
	}
	::-webkit-scrollbar-track {
		background-color: $backgroundColor;
		border-radius: $borderRadius;
	}
	::-webkit-scrollbar-thumb {
		border-radius: $borderRadius;
		background: $thumbColor;
		width: $thumbWidth;
		height: $thumbHeight;
	}
	::-webkit-scrollbar-thumb:window-inactive {
		background-color: transparent;
		border: 1px solid $thumbColor;
	}
}

// BOX SHADOW
@mixin box-shadow() {
	-webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
}

@mixin box-shadow-custom($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color;
	box-shadow: $hoffset $voffset $blur $spread $color;
}

@mixin box-shadow-inset($spread, $color) {
	-webkit-box-shadow: inset 0 0 $spread $color;
	-moz-box-shadow: inset 0 0 $spread $color;
	box-shadow: inset 0 0 $spread $color;
}
