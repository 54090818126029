/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Profile {
	height: 100%;
	color: $text-dark;
	padding: 2em 2.6em 0 2.6em;
	background-image: url('../../assets/images/background-stairs.jpg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;

	.AvatarImage {
		width: 8em;
		height: 8em;
	}

	.Profile-title {
		margin-bottom: 1.5em;
		font-size: 1.9em;
		font-weight: 700;
	}

	.Profile-label {
		font-size: 1.25em;
		font-weight: 400;
		text-align: left;
		margin-bottom: 0.3em;
		margin-left: -0.3em;
		padding-left: 1em;
	}

	.Profile-form {
		margin: 2em auto 0.5em auto;
		width: 17.3em;
		@include input-placeholder($placeholder-grey);
		Input {
			height: 2.4em;
			font-size: 0.9em;
			border-radius: 0.421em;
			border: 0.053em solid $light-blue;
			color: $text-dark;
			&:focus { outline: none;}
			&[name="name"] {
				width: 14.8em;
				margin-left: -0.2em;
				padding-left: 0.9em;
			}
			&[name="age"] {
				width: 4em;
				margin-left: 0.368em;
				text-align: center;
			}
		}

		.Profile-formEl {
			margin-top: 1.3em;
			text-align: left;
			&--experience {
				margin-top: 2.2em;
				margin-bottom: 2em;
				@include flex(left, flex-start, row);
			}
		}
	}

	.Profile-feedback {
		font-size: 0.9em;
		height: 3em;
		white-space: nowrap;
		overflow: visible;
		text-align: center;
	}

	.Select.education {
		font-size: 0.85em;
		width: 20.25em;
		.Select-selected {
			padding-top: 0.5em;
		}
	}

	.Select.experience {
		font-size: 0.85em;
		width: 4.5em;
		margin-left: 0.368em;
		
		.Select-selected {
			padding-left: 0.7em;
		}
	}
}