/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Home {
	position: relative;
	height: 100%;
	padding: 3.5em 0em 2em 0em;
	background-image: url('../../assets/images/background-stairs.jpg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.Home-text {
	color: $text-dark;
	padding: 2em 2em 5.5em 2em;
	span {
		font-size: 1.25em;
	}
}

.Home-resultBtn {
	margin-top: 3em;
	&--disabled .Button {
		opacity: 0.5;
		background: grey;
		&:hover,
		&:focus,
		&:active {
			background: grey;
		}				
		cursor: not-allowed;

	}
}

.Home-partBtns {
	margin-top: 2em;
	.Button {
		margin: 0 0.55em;
	}
}

.Home-firstPartDone,
.Home-resultBtnInfo {
	position: absolute;
	left: 1em;
	right: 1em;
	top: 16.5em;
	text-align: center;
	color: $text-dark;
	padding: 1em 1em;
	background: linear-gradient(#C6E7FF, #7CC6FF);
	border-radius: 0.5em;
	&::after {
		content: "";
		position: absolute;
		bottom: -1.25em;
		right: 4em;
		height: 0;
		width: 0;
		border-top: 1.5em solid #7CC6FF;
		border-left: 1em solid transparent;
		border-right: 1em solid transparent;
	}
}

.Home-resultBtnInfo {
	top: 25em;
	background: linear-gradient(#7CC6FF, #C6E7FF);
	&::after {
		content: "";
		top: -1.25em;
		right: 9.25em;
		height: 0;
		width: 0;
		border-top: none;
		border-left: 1em solid transparent;
		border-right: 1em solid transparent;
		border-bottom: 1.5em solid #7CC6FF;
	}
}

.Home-firstPartDoneTitle {
	font-size: 1.25em;
	font-weight: 600;
	text-transform: uppercase;
}

.Home-firstPartDoneText,
.Home-resultBtnInfoText {
	font-size: 1.25em;
}


.Home-footer {
	position: fixed;
	bottom: 1em;
	left: 0;
	right: 0;
	height: 1.625em;
	background-image: url('../../assets/images/vuc-logo.png');
	background-size: auto 100%;
	background-position: bottom center;
	background-repeat: no-repeat;
}

.Home.Home--landscape {
	background-image: url('../../assets/images/background-landscape.jpg');
	background-size: cover;
	padding: vw-calc(150) 0em 2em 0em;
	.Home-buttons {
		margin-top: vw-calc(100);
	}
	.Home-resultBtn,
	.Home-partBtns {
		width: 66.67%;
		display: inline-block;
		margin-top: 0;
	}	
	.Home-resultBtn { 
		float: right;
		width: 33.33%;
		text-align: left;
	}
	.Home-firstPartDone,
	.Home-resultBtnInfo {
		position: absolute;
		left: auto;
		right: vw-calc(790);
		top: vw-calc(670);
		width: vw-calc(420);
		padding: vw-calc(20);
		border-radius: vw-calc(10);
		transform-style: preserve-3d;
		background-image: linear-gradient(#C4E6FF, #7DC6FF);
		&::after {
			content: "";
			position: absolute;
			bottom: vw-calc(145);
			right: vw-calc(170);
			height: 0;
			width: 0;
			border-style: none;
			border-bottom: vw-calc(30) solid #C6E7FF;
			border-left: vw-calc(30) solid transparent;
			border-right: vw-calc(30) solid transparent;
			transform: translateZ(-1px);
		}
	}
	
	.Home-resultBtnInfo {
		top: vw-calc(670);
		right: vw-calc(200);
		background-image: linear-gradient(#C4E6FF, #7DC6FF);
		&::after {
			top: auto;
			bottom: vw-calc(140);
	
		}
	}
	
	.Home-firstPartDoneTitle {
		font-size: vw-calc(30);
		font-weight: 600;
		text-transform: uppercase;
	}
	
	.Home-firstPartDoneText,
	.Home-resultBtnInfoText {
		font-size: vw-calc(25);
	}
	.Home-footer {
		position: absolute;
		height: vw-calc(45);
		bottom: vw-calc(30);
	}
}