/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.ActionsLandscape {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: vw-calc(170);
	color: $text-dark;
	background-color: #D5ECFE;
	.ChallengeLandscape-actions {
		min-height: auto;
	}
	.ChallengeLandscape-actionWrap {
		display: inline-block;
		vertical-align: top;	
		z-index: 1;
		.ChallengeAction .ChallengeAction-body .ChallengeAction-energy {display: none;}
	}	


.ActionsLandscape-body {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
}

.ActionsLandscape-selected {
	position: relative;
	height: vw-calc(300);
	border-bottom: dashed vw-calc(2) #61A4D8;
	padding-top: vw-calc(145);
	.ChallengeLandscape-actionWrap {
		position: absolute;
		top: vw-calc(145);
		left: vw-calc(505);
		.ChallengeAction.ChallengeAction--placeholder {
			opacity: 0.6;
			background-image: none;
			background-color: #ffffff;
		}
	}	
}



.ActionsLandscape-selectedArrow {
	position: absolute;
	top: vw-calc(145);
	left: vw-calc(765);
	width: vw-calc(35);
	height: vw-calc(100);
	background-image: url('../../assets/images/challenge/action-arrow-right.svg');
	background-size: auto vw-calc(45);
	background-position: center center;
	background-repeat: no-repeat;	
	&--1 {left: vw-calc(1060);}
	&--2 {left: vw-calc(1355);}
}

.ActionsLandscape-options {
	position: relative;
	height: calc(100% - vw-calc(300));
	text-align: left;
	padding: vw-calc(50) vw-calc(110);
	.ChallengeLandscape-actionWrap {
		display: inline-block;
		vertical-align: top;	
		margin-left: vw-calc(20);
		margin-bottom: vw-calc(40);
		&.selected {cursor: not-allowed;}
	}	
}
.ActionsLandscape-optionArrows {
	width: 100%;
	height: vw-calc(25);
	margin-bottom: vw-calc(10);
}
.ActionsLandscape-optionArrow {
	position: relative;
	margin-left: vw-calc(20);
	width: vw-calc(260);
	height: vw-calc(25);
	display: inline-block;
	vertical-align: top;	
	background-image: url('../../assets/images/challenge/action-arrow-white.svg');
	background-size: contain;
	background-position: bottom center;
	background-repeat: no-repeat;	
}

	.Button.Button--evaluateActions {
		position: absolute;
		top: vw-calc(150);
		left: vw-calc(1400);
	}
}