/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.FullscreenToggle {
	position: absolute;
	top: 0;
	right: 0;
	height: vw-calc(80);
	width: vw-calc(80);
	cursor: pointer;
	background-image: url('../../assets/images/icon-fullscreen-expand.svg');
	background-size: vw-calc(40) auto;
	background-position: center center;
	background-repeat: no-repeat;
	&:hover {@include scale(1.1);}
	&--isFullscreen {
		background-image: url('../../assets/images/icon-fullscreen-contract.svg');
	}
}
