/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Interests {
	width: 100%;
	height: 100%;
	color: $text-dark;
	background-image: url('../../assets/images/background-stairs.jpg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	&--landscape {
		background-image: url('../../assets/images/background-landscape.jpg');
		background-size: cover;
		padding-top: vw-calc(200);
		.Interests-header {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: vw-calc(170);
			background-color: rgba(white, 0.5);
			text-align: left;
			z-index: 2;
			@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
		}
		.Interests-headerAvatarInfo {
			text-align: left;
			display: inline-block;
			vertical-align: top;
			width: vw-calc(610);
			height: vw-calc(170);
			background-image: linear-gradient(#A4D0F1, #BADFFA);
			padding: vw-calc(20) vw-calc(20) vw-calc(20) vw-calc(225);
		}
		.Interests-headerAvatarInfoText {
			font-size: vw-calc(20);
			padding-top: 1.25em;
			span {
				display: inline-block;
				vertical-align: top;
				font-size: 1.5em;
				font-weight: bold;
			}
		}
	
		.Interests-headerAvatarStats {
			display: inline-block;
			vertical-align: top;
			width: vw-calc(655);
			height: vw-calc(170);
		}

		.Interests-carousel {
			position: relative;
			top: auto;
			height: 100%;
		}
	}
}

.Interests-carousel {
	position: absolute;
	top: 10.25em;
	width: 100%;
	height: calc(100% - 10.25em);
}

.Interests-interestsWrap {
	position: absolute;
	top: 1em;
	left: 2.625em;
	width: 17.25em;
	height: 100%;
	padding-left: 1.125em;
	will-change: transform;
}

.Interests-card {
	position: relative;
	text-align: left;
	width: 15em;
	height: 27.5em;
	padding: 0.5em 1.25em;
	border-radius: $border-radius;
	background-color: #F0F0F0;
	will-change: transform;
	@include box-shadow-custom(0, 0.1875em, 0.375em, 0, rgba(0, 0, 0, 0.16));
}

.Interests-interestTitle {
	position: relative;
	height: 3.25em;
	font-size: 1.25em;
	font-weight: bold;
	line-height: 1;
	text-align: center;
}

.Interests-interestText {
	font-weight: normal;
	padding-top: 1em;
	margin-bottom: 1em;
}

.Interests-selectInterest {
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 1em;
	left: 0;
}


