/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Diploma {
	text-align: left;
	padding: 0.5em 0.6em;
	position: relative;
	color: $text-dark;
	height: 100%;
	overflow: scroll;
	background-color: #fff;
	-webkit-overflow-scrolling: touch;

	.Diploma-emailBtn {
		position: fixed;
		bottom: 1em;
		left: 0;
		right: 0;
		z-index: 100;
		text-align: center;
		.Button {
			margin: 0;
			width: auto;
			padding: 0.25em 1em;
		}
	}

	.Diploma-close-icon {
		position: absolute;
		top: 1.1em;
		right: 1em;
		width: 1em;
		height: 1em;
		cursor: pointer;
		z-index: 100;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('../../assets/images/icon-close.svg');
		&:hover {
			@include scale(1.1);
		}
	}

	.Diploma-body {
		border-radius: $border-radius;
		border: 1px solid $text-dark;
	}
	
	.Diploma-line {
		width: 100%;
		height: 1px;
		border-bottom: 1px solid rgba($color: $text-dark, $alpha: 0.1);
		margin-bottom: 0.5em;
	}
	
	.Diploma-section {
		padding: 0.5em 0.7em;
		position: relative;
	}

	.Diploma-medal {
		width: 6em;
		height: 6em;
		background-image: url('../../assets/images/icon-medal.svg');
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.Diploma-title {
		position: absolute;
		top: 2em;
		width: 100%;
		text-align: center;
		font-size: 1.3em;
		font-weight: 700;
	}

	.Diploma-name,
	.Diploma-score,
	.Diploma-nextStep,
	.Diploma-answers {
		display: inline-block;
		font-size: 1.3em;
		font-weight: 700;
		margin-bottom: 0.3em;
	}

	.Diploma-name {
		margin-right: 0.5em;
	}

	.Diploma-age {
		display: inline-block;
	}

	.Diploma-score {
		border-top: none;
		margin-bottom: 0.3em;
	}

	.AvatarStats-stats.AvatarStats-stats--diploma {
		position: relative;
		left: initial;
		right: initial;
		height: auto;
		bottom: initial;
		border:none;
		@include flex("space-between", "center", "row");

		.AvatarStats-stat {
			width: 3.2em;
			border-left: 1px solid rgba($color: $text-dark, $alpha: 0.1);
		}
	}

	.Diploma-interest-heading,
	.Diploma-education-heading,
	.Diploma-experience-heading {
		margin-top: 0.5em;
		font-weight: 700;
	}

	.Diploma-action {
		@include flex("flex-start", "center", "row");
		margin-top: 0.4em;
		&.red .Diploma-action-index {
			background-color: $red;
		}
		&.yellow .Diploma-action-index {
			background-color: $yellow;
		}
		&.green .Diploma-action-index {
			background-color: $cyan-blue;
		}

		.Diploma-action-index {
			margin-right: 0.3em;
			padding: 0.05em 0.45em;
			width: 1.5em;
			height: 1.5em;
			border-radius: 100%;
			font-size: 1.3em;
			font-weight: 700;
			color: #fff;
		}
	}

	.Button {
		margin-left: 8.5em;
		margin-bottom: 2em;
	}

	.Diploma-emailItem,
	.Diploma-form {
		position: relative;
	}

	.Diploma-removeEmail {
		display: inline-block;
		line-height: 1em;
		width: 1em;
		height: 1em;
		background-image: url('../../assets/images/icon-minus-black.svg');
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		top: 0.35em;
		margin-left: 0.5em;
	}

	.Diploma-addEmail {
		display: inline-block;
		width: 1em;
		height: 1em;
		background-image: url('../../assets/images/icon-plus-black.svg');
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
		margin-left: 0.2em;
		margin-bottom: -0.3em;
	}

	.Diploma-feedback {
		display: inline-block;
		margin-left: 0.5em;
	}

	.Diploma-emailSentSuccess,
	.Diploma-emailSentFailure {
		margin-bottom: 0.5em;
	}

	.Diploma-table {
		border-collapse: collapse;
		margin: 0 auto 1.5em auto;
		width: 17.7em;
		th:first-child,
		td:first-child {
			width: 15.5em;
			min-width: 15.5em;
			max-width: 15.5em;
			padding: 0.3em 0.5em;
		}
		th:last-child,
		td:last-child {
			width: 2.2em;
			min-width: 2.2em;
			max-width: 2.2em;
			text-align: center;
		}
		th {
			height: 2.3em;
			font-size: 1.1em;
		}
		tr:first-child th {
			border: none;
		}
		th:first-child {
			@include flex("flex-start", "center", "row");
		}
		.Diploma-th-icon {
			width: 1.9em;
			height: 1.7em;
			display: inline-block;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			margin-right: 0.3em;
		}
		&--danish .Diploma-th-icon {
			background-image: url('../../assets/images/stats/danish.svg');
		}
		&--reading .Diploma-th-icon {
			background-image: url('../../assets/images/stats/reading.svg');
		}
		&--math .Diploma-th-icon {
			background-image: url('../../assets/images/stats/math.svg');
		}
		&--teamwork .Diploma-th-icon {
			background-image: url('../../assets/images/stats/teamwork.svg');
		}
		&--workEthic .Diploma-th-icon {
			background-image: url('../../assets/images/stats/workEthic.svg');
		}
	}
	
	.Diploma-table,
	.Diploma-table th,
	.Diploma-table td {
		border: 1px solid #707070;
	}
}
