/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.AvatarImage {
	display: inline-block;
	width: 11.2em;
	height: 11.2em;
	border-radius: 11.2em;
	background-size: auto 11.2em;
	background-repeat: no-repeat;
	background-position: top 0.3em center;
	@include box-shadow-custom(0.1em, 0.35em, 0.3em, 0, rgba(0, 0, 0, 0.22));
	pointer-events: auto;
	background-color: white;
	pointer-events: none;
	&--clickable {
		pointer-events: all;
		cursor: pointer;
	}
	&--0 {
		background-image: url('../../assets/images/avatars/default.svg');
		background-position: center center;
		.AvatarImage-letter {
			color: white;
			font-size: 4.875em;
			font-weight: 500;
			padding-top: 0.1em;
		}
	}
	&--1 {background-image: url('../../assets/images/avatars/1.svg');}
	&--2 {background-image: url('../../assets/images/avatars/2.svg');}
	&--3 {background-image: url('../../assets/images/avatars/3.svg');}
	&--4 {background-image: url('../../assets/images/avatars/4.svg');}
	&--5 {background-image: url('../../assets/images/avatars/5.svg');}
	&--6 {background-image: url('../../assets/images/avatars/6.svg');}
	&--7 {background-image: url('../../assets/images/avatars/7.svg');}
	&--8 {background-image: url('../../assets/images/avatars/8.png');}
	&--9 {background-image: url('../../assets/images/avatars/9.svg');}
	&--10 {background-image: url('../../assets/images/avatars/10.svg');}
	
	&--avatars {
		width: 100%;
		height: 13.5em;
		border-radius: 11.5em;
		background-size: 11.25em auto;
		background-position: top 0.5em center;
	}

	&--avatarInfo {
		position: absolute;
		left: calc(9.25em - 2.3125em);
		left: calc(50% - 2.3125em);
		top: 0;
		width: 4.625em;
		height: 4.625em;
		border-radius: 4.625em;
		background-size: 3.25em auto;
		background-position: top 0.5em center;
		@include box-shadow-custom(0, 0.1875em, 0.375em, 0, rgba(0, 0, 0, 0.16));
	}
	&.AvatarImage--0.AvatarImage--avatarInfo {
		background-size: initial;
		background-position: center center;
		.AvatarImage-letter {
			font-size: 2.875em;
		}
	}

	&--goals,
	&--challenge,
	&--quiz {
		position: absolute;
		left: 1em;
		top: 1.5em;;
		width: 4.75em;
		height: 4.75em;
		border-radius: 4.75em;
		background-size: 3.25em auto;
		background-position: top 0.5em center;
		@include box-shadow-custom(0, 0.1875em, 0.375em, 0, rgba(0, 0, 0, 0.16));
	}

	&.AvatarImage--0.AvatarImage--quiz {
		background-size: initial;
		background-position: center center;
		.AvatarImage-letter {
			font-size: 2.875em;
		}
	}
}

.AvatarImage.AvatarImage--landscape {
	width: vw-calc(390);
	height: vw-calc(390);
	border-radius: vw-calc(390);
	background-size: auto vw-calc(390);
	background-repeat: no-repeat;
	background-position: top vw-calc(50) center;
	@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(0, 0, 0, 0.16));

	&.AvatarImage--0 {
		background-image: linear-gradient(#66CCCC, #519F9F);
		background-position: top center;
		.AvatarImage-letter {
			font-size: vw-calc(140);
			padding-top: 0.1em;
		}
	}

	&.AvatarImage--avatars {
		width: 100%;
		background-size: vw-calc(280) auto;
		background-position: top vw-calc(50) center;
	}
	&.AvatarImage--small {
		width: vw-calc(150);
		height: vw-calc(150);
		background-size: vw-calc(110) auto;
		background-position: top vw-calc(15) center;
		margin: auto;
	}
	&.AvatarImage--goals,
	&.AvatarImage--challenge,
	&.AvatarImage--quiz,
	&.AvatarImage--interests,
	&.AvatarImage--actions {
		width: vw-calc(124);
		height: vw-calc(124);
		background-size: vw-calc(90) auto;
		background-position: top vw-calc(20) center;
		margin: auto;
	}
	&.AvatarImage--goals,
	&.AvatarImage--challenge,
	&.AvatarImage--quiz,
	&.AvatarImage--interests,
	&.AvatarImage--actions {
		position: absolute;
		top: vw-calc(23);
		left: vw-calc(75);
	}
	&.AvatarImage--quiz,
	&.AvatarImage--interests,
	&.AvatarImage--actions {
		background-size: cover;
		background-position: top center;
	}
	&.AvatarImage--0.AvatarImage--quiz {
		.AvatarImage-letter {
			text-align: center;
			font-size: vw-calc(80);
			padding-top: 0.05em;
		}
	}
	&.AvatarImage--0.AvatarImage--interests {
		.AvatarImage-letter {
			text-align: center;
			font-size: vw-calc(80);
			padding-top: 0.05em;
		}
	}
	&.AvatarImage--0.AvatarImage--actions {
		.AvatarImage-letter {
			text-align: center;
			font-size: vw-calc(80);
			padding-top: 0.05em;
		}
	}

}