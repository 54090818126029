/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.ActionInfoLandscape {
	position: absolute;
	top: vw-calc(300);
	left: vw-calc(300);
	width: vw-calc(365);
	min-height: vw-calc(195);
	border-radius: vw-calc(10);
	background: $white;
	color: $text-dark;
	z-index: 3;
	&--actions0 {top: vw-calc(360); left: vw-calc(365);}
	&--actions1 {top: vw-calc(360); left: vw-calc(645);}
	&--actions2 {top: vw-calc(360); left: vw-calc(925);}
	&--actions3 {top: vw-calc(500); left: vw-calc(365);}
	&--actions4 {top: vw-calc(500); left: vw-calc(645);}
	&--actions5 {top: vw-calc(500); left: vw-calc(925);}
	&--actions6 {top: vw-calc(640); left: vw-calc(365);}
	&--actions7 {top: vw-calc(640); left: vw-calc(645);}
	&--actions8 {top: vw-calc(640); left: vw-calc(925);}
	&--actions9 {top: vw-calc(780); left: vw-calc(365);}
	&--actions10 {top: vw-calc(780); left: vw-calc(645);}
	&--actions11 {top: vw-calc(780); left: vw-calc(925);}
	&--actions12 {top: vw-calc(920); left: vw-calc(365);}
	&--actions13 {top: vw-calc(920); left: vw-calc(645);}
	&--actions14 {top: vw-calc(920); left: vw-calc(925);}
	&::after {
		font-size: vw-calc(16);
		content: "";
		position: absolute;
		top: -1em;
		height: 0;
		width: 0;		
		left: 2em;
		top: -1.75em;
		border-bottom: 2em solid #4B6E72;
		border-left: 0.25em solid transparent;
		border-right: 1.75em solid transparent;
	}		
	&--selected {
		left: vw-calc(1300);
		top: vw-calc(390);
		&::after {
			border-left-width: 1.75em;
			border-right-width: 0.25em;
			right: 2em;
			left: auto;
		}
	}
}

.ActionInfoLandscape-header {
	position: relative;
	border-top-left-radius: vw-calc(10);
	border-top-right-radius: vw-calc(10);
	background-color: #4B6E72;
	padding: vw-calc(25) vw-calc(25);
	text-align: left;
	&--big {
		padding: vw-calc(20) vw-calc(25);
	}
}

.ActionInfoLandscape-title {
	position: relative;
	color: white;
	font-size: vw-calc(20);
	font-weight: bold;		
	// text-transform: uppercase;
}

.ActionInfoLandscape-closeBtn {
	position: absolute;
	top: 0;
	right: 0;
	width: vw-calc(50);
	height: vw-calc(50);
	cursor: pointer;
	z-index: 100;
	background-size: vw-calc(20) auto;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url('../../assets/images/icon-close-white.svg');
	&:hover {@include scale(1.1);}
}


.ActionInfoLandscape-actionRequirements {
	position: relative;
	margin-top: vw-calc(10);
}

.ActionInfoLandscape-requirementTitle {
	position: relative;
	font-weight: 700;
	font-size: vw-calc(18);
	line-height: 2;
	padding-left: 2.5em;
	height: 2em;
	color: white;
	&--2 {line-height: 1;}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0.375em;
		width: 1.25em;
		height: 1.25em;
		background-color: white;
		border: 1px solid $red;
		box-sizing: content-box;
		background-image: url('../../assets/images/icon-checked-false.svg');
		background-position: center center;
		background-size: 85% auto;
		background-repeat: no-repeat;
	}
	&--ok {
		&::before {
			border: 1px solid $green;
			background-image: url('../../assets/images/icon-checked-true.svg');
		}
	}
}

.ActionInfoLandscape-stats {
	padding: vw-calc(20) vw-calc(20) vw-calc(20) 0;
}

.ActionInfoLandscape-statTypes {
	border-bottom: vw-calc(1) solid rgba(#156B6F, 0.1);
	height: vw-calc(30);
	text-align: right;
}

.ActionInfoLandscape-statType {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: vw-calc(50);
	height: vw-calc(30);
	border-left: vw-calc(1) solid rgba(#156B6F, 0.4);
	background-size: auto vw-calc(24);
	background-repeat: no-repeat;
	background-position: center top;

	&--danish {background-image: url('../../assets/images/stats/danish.svg');}
	&--reading {background-image: url('../../assets/images/stats/reading.svg');}
	&--math {background-image: url('../../assets/images/stats/math.svg');}
	&--teamwork {background-image: url('../../assets/images/stats/teamwork.svg');}
	&--workEthic {background-image: url('../../assets/images/stats/workEthic.svg');}
}

.ActionInfoLandscape-statsRequirements,
.ActionInfoLandscape-reward {
	text-align: right;
	height: vw-calc(35);
	border-bottom: vw-calc(1) solid rgba(#156B6F, 0.1);
}
.ActionInfoLandscape-reward {
	border-style: none;
}

.ActionInfoLandscape-requiredStatLabel,
.ActionInfoLandscape-rewardLabel {
	position: relative;
	display: inline-block;
	vertical-align: top;
	font-size: vw-calc(20);
	line-height: 1.75em;
	// width: 4.7em;
	height: 1.75em;
	padding-right: 0.5em;
}


.ActionInfoLandscape-requiredStatValue,
.ActionInfoLandscape-rewardedValue {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: vw-calc(50);
	height: vw-calc(35);
	border-left: vw-calc(1) solid rgba(#156B6F, 0.4);
	line-height: vw-calc(35);
	text-align: center;
	color: $green;
	span {
		vertical-align: top;
		font-weight: bold;
		font-size: vw-calc(25);
		padding: 0;
	}
}

.ActionInfoLandscape-requiredStatValue {
	color: $red;
	&--ok {
		color: $green;
	}
}
