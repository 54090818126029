/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.AvatarCard-name {
	width: 100%;
	top: 1.875em;
	left: 0;
	text-align: center;
	span {
		font-size: 1.875em;
		font-weight: bold;
		text-transform: uppercase;	
	}
}

.AvatarCard-info {
	padding: 0.5em;
}


.AvatarCard-selectAvatar {
	position: absolute;
	width: 100%;
	bottom: 1em;
	left: 0;
	text-align: center;
}


.AvatarCard.AvatarCard--landscape {
	span {font-size: vw-calc(50)};
}