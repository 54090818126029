/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Admin {
	font-size: 16px;
	color: black;
	background: white;
	padding: 1em 2em;
}

.Admin-link {
	width: 16em;
	background-color: lightgrey;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(lightgrey, 5%);}
}