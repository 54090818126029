$border-radius: 0.25em;

/* Colors */
// $text-dark: #354C4E;
$text-dark: #4B6E72;
$text-darkest: #223E43;
$placeholder-grey: #989898;

$grey: #CCD1D9;
$grey-dark: #656D78;
$grey2: #BFBFBF;
$grey-light: #F0F0F0;
$white: #fff;
$white-blue: #F2FAF9;

$blue: #61A4D8;
$light-blue: #BCE0FD;
$cyan-blue: #46CAB6;

$cyan: #41BFBF;

$green: #42BFAC;
$red: #F15156;
$yellow: #FFD75E;
$orange: #F6AD43;
/* Images */


/* Icons */


/* Cosinus and sinus using Taylor expansions */
@function pi() {
  @return 3.14159265359;
}

@function pow($number, $exp) {
  $value: 1;
  @if $exp > 0 {
    @for $i from 1 through $exp {
      $value: $value * $number;
    }
  } @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: $value / $number;
    }
  }
  @return $value;
}

@function fact($number) {
  $value: 1;
  @if $number > 0 {
    @for $i from 1 through $number {
      $value: $value * $i;
    }
  }
  @return $value;
}

@function sin($angle) {
  $sin: 0;
  @for $i from 0 through 10 {
    $sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
  }
  @return $sin;
}

@function cos($angle) {
  $cos: 0;
  @for $i from 0 through 10 {
    $cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
  }
  @return $cos;
}