/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.GoalCardLandscape {
	text-align: left;
	height: 100%;
	padding: vw-calc(35) vw-calc(45);
	background-color: #F1F9FF;
	@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(0, 0, 0, 0.16));
	
}

.GoalCardLandscape-title {
	position: relative;
	height: vw-calc(70);
	text-align: center;
	margin-bottom: vw-calc(20);
	span {
		display: inline-block;
		vertical-align: top;
		font-size: vw-calc(35);
		font-weight: bold;
		line-height: 1;
	
	}
}

.GoalCardLandscape-image {	
	margin: 0 auto;
	width: vw-calc(156);
	height: vw-calc(156);
	border-radius: vw-calc(156);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url('../../assets/images/goals/default.svg');
	&--food_health {background-image: url('../../assets/images/goals/food_health.svg');}
	&--office_commerce {background-image: url('../../assets/images/goals/office_commerce.svg');}
	&--caregiving {background-image: url('../../assets/images/goals/caregiving.svg');}
	&--design_media {background-image: url('../../assets/images/goals/design_media.svg');}
	&--animals_nature_environment {background-image: url('../../assets/images/goals/animals_nature_environment.svg');}
	&--crafts_construction {background-image: url('../../assets/images/goals/crafts_construction.svg');}
	&--it_electronics {background-image: url('../../assets/images/goals/it_electronics.svg');}
	&--engineering_mechanics_machines {background-image: url('../../assets/images/goals/engineering_mechanics_machines.svg');}
	&--transport_logistics {background-image: url('../../assets/images/goals/transport_logistics.svg');}
}

.GoalCardLandscape-text {
	padding-top: vw-calc(20);
	margin-bottom: vw-calc(40);
	span {
		display: inline-block;
		vertical-align: top;
		font-size: vw-calc(30);
		line-height: 1.3;
		font-weight: normal;
	}
}

.GoalCardLandscape-requirements {
	border-top: vw-calc(2) solid rgba(#707070, 0.4);
	padding-top: vw-calc(20);
}

.GoalCardLandscape-requirementsTitle {
	font-size: vw-calc(30);
	font-weight: bold;
}

.GoalCardLandscape-requirement {
	position: relative;	
	padding-left: vw-calc(40);
	height: vw-calc(50);
	line-height: vw-calc(50);
	margin-top: vw-calc(10);
	span {
		display: inline-block;
		vertical-align: top;
		font-size: vw-calc(30);
	}
	&::before {
		content: "•";
		position: absolute;
		left: vw-calc(0);
		// top: vw-calc(10);
		width: vw-calc(20);
		height: vw-calc(20);
		color: $red;
	}
}


.GoalCardLandscape-selectCard {
	position: absolute;
	width: 100%;
	height: vw-calc(65);
	bottom: vw-calc(40);
	left: 0;
	text-align: center;
}

// @media screen and (max-height: 668px) {
// 	.GoalCardLandscape-title {
// 		height: 2.3em;
// 	}

// 	.GoalCardLandscape-text {
// 		padding-top: 0.7em;
// 		margin-bottom: 0.7em;
// 	}	
// }