/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Popup-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: rgba(black, .5);
	@include flex('center','center');
}

.Popup {
	position: relative;
	width: 20em;
	text-align: left;	
	color: $text-dark;
	border-radius: 0.625em;
	background: linear-gradient(#FDF7F7, #DEDEDE);
	overflow: hidden;
	z-index: 1;
	@include box-shadow-custom(0, 0.1875em, 0.375em, 0, rgba(black, 0.16));
}

.Popup-body {
	padding: 1em 2em;
}

.Popup-title {
	font-weight: bold;
}

.Popup-text {
	margin-top: 1em;
}

.Popup-buttons {
	width: 100%;
	margin-top: 1em;
	text-align: right;
	&--2 {
		text-align: center;
		.Button {margin: 0 0.5em;}
	}
}

.Popup-closeBtn {
	position: absolute;
	right: 0.5em;
	top: 0.5em;
	width: 1.3em;
	height: 1.3em;
	background-image: url('../../assets/images/icon-close-cyan.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}


/* Intro popups */
.Popup--introPopup {
	height: auto;
	background: linear-gradient(#C6E7FF, #7CC6FF);
	margin-bottom: 0em;
	.Popup-body {padding: 1.5em 1.5em;}
	.Popup-title,
	.Popup-text,
	.Popup-buttons {
		font-size: 1.125em;
		text-align: center;
	}
	.Popup-text {margin-bottom: 0;}
	.Popup-closeBtn {
		width: 1.1em;
		height: 1.1em;
		background-image: url('../../assets/images/icon-close.svg');
	}
}

/* Part 1: challenge start warning popups */
.Popup--noActionsAvailable,
.Popup--redGoal,
.Popup--notEnoughEnergy,
.Popup--notEnoughEnergyAndRedActions{
	background: linear-gradient(#FDF7F7, #DEDEDE);
	text-align: center;
	.Popup-text {
		background-size: 11.5em auto;
		background-position: bottom center;
		background-repeat: no-repeat;
		padding-bottom: 10em;
	}
	.Popup-buttons {text-align: center;}
}
.Popup--noActionsAvailable .Popup-text {
		background-image: url('../../assets/images/popup-challenge-noActionsAvailable.svg');
}
.Popup--redGoal .Popup-text {
		padding-bottom: 7em;
		background-image: url('../../assets/images/popup-challenge-redGoal.svg');
}
.Popup--notEnoughEnergy,
.Popup--notEnoughEnergyAndRedActions {
	text-align: left;
	background: linear-gradient(#FFDE79, #E5B41F);
	.Popup-title {
		text-align: center;
	}
	.Popup-text {
		padding: 0;
	}
}

/* Part 1: confirm start challenge popup */
.Popup--start-challenge-confirm {
	.Popup-title {text-align: center;}
}


/* Part 1: challenge failed popup */
.Popup--challenge-failed {
	text-align: center;
	.Popup-body {padding: 1.5em 2.5em 2.5em 2.5em;}
	.Popup-buttons {text-align: center;}
}


/* Part 1: challenge completed popup */
$extraBounceDelay1: 2;
$extraBounceDelay2: 2.8;
$extraBounceDelay3: 3.8;

.Popup--challenge-completed {
	width: 20.625em;
	background: linear-gradient(#C6E7FF, #7CC6FF);
	&.Popup--icons-1 {
		// -webkit-animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards, extraBounce 1s cubic-bezier(0.280, 0.840, 0.420, 1) #{$extraBounceDelay1}s 1 forwards;
		// animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards, extraBounce 1s cubic-bezier(0.280, 0.840, 0.420, 1) #{$extraBounceDelay1}s 1 forwards;
		-webkit-animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;

	}
	&.Popup--icons-2 {
		// -webkit-animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards, extraBounce 1s cubic-bezier(0.280, 0.840, 0.420, 1) #{$extraBounceDelay2}s 1 forwards;
		// animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards, extraBounce 1s cubic-bezier(0.280, 0.840, 0.420, 1) #{$extraBounceDelay2}s 1 forwards;
		-webkit-animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;

	}
	&.Popup--icons-3 {
		// -webkit-animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards, extraBounce 1s cubic-bezier(0.280, 0.840, 0.420, 1) #{$extraBounceDelay3}s 1 forwards;
		// animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards, extraBounce 1s cubic-bezier(0.280, 0.840, 0.420, 1) #{$extraBounceDelay3}s 1 forwards;
		-webkit-animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation: bounceOpen 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;

	}

	.Popup-title {
		font-size: 1.25em;
		text-align: center;
	}
	.Popup-body {
		padding: 1em 1.5em;
	}
	.Popup-text {
		line-height: 1.5;
		font-weight: normal;
		text-align: center;
	}
	.Popup-buttons {
		@include flex("space-between", "center", "row");
		padding-bottom: 0.9em;
		.Button {
			text-transform: none;
		}
	}
	.Popup-icons {
		position: relative;
		text-align: center;
		margin-bottom: 2em;
	}
}

.Popup-fireworks {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
}
.Popup-fireworksObject {
	position: absolute;
	width: 0.3em;
	height: 0.3em;
	border-radius: 1em;
	opacity: 0;
}
$objects: 300;
$maxStars: 3;
@for $i from 1 through $objects {
	$angle: ((random(360)) * pi()) / 180.0;
	$length: random(30);
	$x: ($length * cos($angle));
	$y: ($length * sin($angle));
	@-webkit-keyframes fireworks-#{$i} {
		0% {
			top: 50%;
			left: 50%;
			opacity: 1;
		}
		100% {
			-ms-transform: translate(#{$x}em, #{$y}em);
			-webkit-transform: translate(#{$x}em, #{$y}em);
			transform: translate(#{$x}em, #{$y}em);
			opacity: 0;
		}
	}
	
	@keyframes fireworks-#{$i} {
		0% {
			top: 50%;
			left: 50%;
			opacity: 1;
		}
		100% {
			-ms-transform: translate(#{$x}em, #{$y}em);
			-webkit-transform: translate(#{$x}em, #{$y}em);
			transform: translate(#{$x}em, #{$y}em);
			opacity: 0;
		}
	}
	@for $j from 1 through $maxStars {
		.Popup-fireworks--#{$j} {
			$delay: $extraBounceDelay1;
			$color: $yellow;
			@if ($i >= $objects / 2) { $color: white;}
			@if ($j == 2) { $delay: $extraBounceDelay2; }
			@if ($j == 3) { $delay: $extraBounceDelay3; }
			.Popup-fireworksObject {
				&:nth-child(#{$i}){
					left: 50%;
					top: 50%;
					-webkit-animation: fireworks-#{$i} 1s ease-out #{$delay + 0.1}s 1 alternate forwards;
					animation: fireworks-#{$i} 1s ease-out #{$delay + 0.1}s 1 alternate forwards;
					background: $color
				}
			}
		}
	}
}

@-webkit-keyframes bounceOpen {
	0%   {@include scale(0);}
	10%  {@include scale(.9);}
	30%  {@include scale(1.1);}
	60%  {@include scale(.95);}
	100% {@include scale(1);}	
}
@keyframes bounceOpen {
	0%   {@include scale(0);}
	10%  {@include scale(.9);}
	30%  {@include scale(1.1);}
	60%  {@include scale(.95);}
	100% {@include scale(1);}	
}

@-webkit-keyframes extraBounce {
	0%   {@include scale(1);}
	20%  {@include scale(.3);}
	// 30%  {@include scale(1.2);}
	// 60%  {@include scale(.8);}
	100% {@include scale(1);}
}
@keyframes extraBounce {
	0%   {@include scale(1);}
	20%  {@include scale(.3);}
	// 30%  {@include scale(1.2);}
	// 60%  {@include scale(.8);}
	100% {@include scale(1);}
}


/* Part 2: evaluate actions popup */
.Popup--redActions,
.Popup--yellowActions,
.Popup--greenActions {
	color: white;
	margin-bottom: 10em;
	.Popup-title,
	.Popup-buttons {
		text-align: center;
	}
	.Popup-text {
		font-weight: 400;
		padding: 1em 0;
		margin-top: 0;
		p { margin-top: 0; }
	}
	.Popup-body {padding: 1em 1.5em;}
};
.Popup--greenActions { background: linear-gradient(#46CAB6, #29B49F); }
.Popup--redActions { background: linear-gradient(#FC676C, #DE464A); }
.Popup--yellowActions {
	margin-bottom: 11em;
	color: $text-dark;
	background: linear-gradient(#FFDE79, #E5B41F);
}

/* Part 2: email popups */
.Popup--game-result-saved,
.Popup--send-email,
.Popup--close-send-email,
.Popup--email-successful,
.Popup--email-failed {
	background-color: $white-blue;
	margin-bottom: 8.5em;
	height: auto;
	.Popup-text {margin-top: 0;}
	.Popup-buttons {text-align: center;}
	p {margin: 0.5em 0;}
	a {color: $text-dark;}
}

.Popup--send-email {
	p:nth-of-type(1) {
		margin: 0;
	}
	p:nth-of-type(2) {
		margin-top: 0;
		margin-bottom: 1em;
	}
	.Popup-input {
		@include input-placeholder($placeholder-grey);
		Input {
			width: 100%;
			height: 2.4em;
			margin-left: -0.2em;
			padding-left: 0.9em;
			font-size: 0.9em;
			border-radius: 0.421em;
			border: 0.053em solid $light-blue;
			color: $text-dark;
			&:focus { outline: none;}
		}
	}
	.Popup-closeBtn {
		width: 1.1em;
		height: 1.1em;
		background-image: url('../../assets/images/icon-close.svg');
	}
}
.Popup--email-successful,
.Popup--email-failed {
	.Popup-body {
		padding: 1em 1.5em;
	}
}