/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Tips {
	position: absolute;
	left: 0;
	top: vw-calc(60);
	width: vw-calc(300);
	height: vw-calc(580);
	color: $text-dark;
	background: linear-gradient(to right, #DEF0FD, #AFD6F6);
	border-top-right-radius: vw-calc(10);
	border-bottom-right-radius: vw-calc(10);
	text-align: left;
	padding: vw-calc(20) vw-calc(30);
	&--profile {
		top: vw-calc(214);
		width: vw-calc(280);
		height: vw-calc(520);
	}
}

.Tips-title {
	font-size: vw-calc(25);
	font-weight: 700;
	margin-bottom: 1em;
}

.Tips-text {
	display: inline-block;
	vertical-align: top;
	font-size: vw-calc(20);
	span {font-weight: 700;}
	// span.red {color: $red; font-weight: 700;}
	// span.green {color: $green; font-weight: 700;}
	// span.yellow {color: $yellow; font-weight: 700;}	
}

.Tips-nav {
	font-size: vw-calc(20);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: left;
	padding: 1em 1.5em;
}
.Tips-navItem {
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.75em;
	width: 1em;
	height: 1em;
	border-radius: 2em;
	background-color: white;
	border: 1px solid #61A4D8;
	cursor: pointer;
	&:hover,
	&.selected {
		background-color: #61A4D8;
	}
}