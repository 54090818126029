/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.ChallengeLandscape {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: vw-calc(170);
	color: $text-dark;
	background-image: url('../../assets/images/background-landscape.jpg');
	background-size: cover;
	background-position: bottom center;
}

.ChallengeLandscape-body {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
}

.ChallengeLandscape-tips {
	position: absolute;
	left: 0;
	top: vw-calc(60);
	width: vw-calc(280);
	height: vw-calc(510);
	background: linear-gradient(to right, #A4D0F1, #88B8DC);
	border-top-right-radius: vw-calc(10);
	border-bottom-right-radius: vw-calc(10);
	text-align: left;
	padding: vw-calc(100) vw-calc(20) 0 vw-calc(30);
	&::after {
		content: "";
		position: absolute;
		top: vw-calc(30);
		left: vw-calc(30);
		width: vw-calc(50);
		height: vw-calc(50);
		background-image: url('../../assets/images/icon-lightbulb.svg');
		background-size: auto 100%;
		background-position: left center;
		background-repeat: no-repeat;
	}
}
.ChallengeLandscape-tipsText {
	display: inline-block;
	vertical-align: top;
	font-size: vw-calc(20);
	color: white;
	span {font-weight: 700;}
	// span.red {color: $red; font-weight: 700;}
	// span.green {color: $green; font-weight: 700;}
	// span.yellow {color: $yellow; font-weight: 700;}	
}

.ChallengeLandscape-tipsNav {
	font-size: vw-calc(20);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: left;
	padding: 1em 1.5em;
}
.ChallengeLandscape-tipsNavItem {
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.75em;
	width: 1em;
	height: 1em;
	border-radius: 1em;
	background-color: white;
	border: 1px solid #61A4D8;
	cursor: pointer;
	&:hover,
	&.selected {
		background-color: #61A4D8;
	}
}

.ChallengeLandscape-tipsPrevBtn,
.ChallengeLandscape-tipsNextBtn {
	position: absolute;
	bottom: vw-calc(30);
	width: vw-calc(30);
	height: vw-calc(30);
	background-size: auto vw-calc(25);
	background-repeat: no-repeat;
	cursor: pointer;
}
.ChallengeLandscape-tipsPrevBtn {
	left: vw-calc(30);
	background-image: url('../../assets/images/icon-arrow-left.svg');
	background-position: left center;
}
.ChallengeLandscape-tipsNextBtn {
	right: vw-calc(30);
	background-image: url('../../assets/images/icon-arrow-right.svg');
	background-position: right center;
}

.ChallengeLandscape-options {
	position: absolute;
	left: vw-calc(340);
	top: vw-calc(60);
	width: vw-calc(850);
	height: vw-calc(550);
	text-align: left;
	z-index: 2;
	.ChallengeLandscape-actionWrap {
		display: inline-block;
		vertical-align: top;	
		margin-left: vw-calc(20);
		margin-bottom: vw-calc(40);
		&.selected {cursor: not-allowed;}
	}	
}


.ChallengeLandscape-path {
	position: absolute;
	right: 0;
	bottom: 0;
	width: vw-calc(1110);
	height: vw-calc(855);
	background-image: url('../../assets/images/background-selected.svg');
	background-size: contain;
	background-position: bottom right;
	background-repeat: no-repeat;
	z-index: 1;
	overflow: hidden;
	@include hide-scrollbar();
	-webkit-overflow-scrolling: touch;
	&.smoothScroll {
		scroll-behavior: smooth;
	}	
	.ChallengeLandscape-actions {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}
	.ChallengeLandscape-actionWrap {
		position: absolute;
		right: vw-calc(80);
		top: vw-calc(35);
	}
}

.ChallengeLandscape-selectedArrow {
	position: absolute;
	right: vw-calc(185);
	top: vw-calc(5);
	width: vw-calc(50);
	height: vw-calc(30);
	background-image: url('../../assets/images/challenge/action-arrow2.svg');
	background-size: contain;
	background-position: bottom center;
	background-repeat: no-repeat;	
}

.ChallengeLandscape-actions {
	min-height: 100%;
}

.ChallengeLandscape-actionWrap {
	width: vw-calc(260);
	z-index: 1;
	cursor: move;
}

.ChallengeLandscape-buyBtn {
	position: absolute;
	bottom: vw-calc(70);
	right: vw-calc(90);
	width: vw-calc(250);
	height: vw-calc(60);
	text-align: right;
	z-index: 2;
	&--dragging {
		pointer-events: none;
		.Button {
			pointer-events: none;
		}
	}
}

.ChallengeLandscape-overlay {
	position: absolute;
	background-color: transparent;
	top: vw-calc(170);
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	pointer-events: none;
	&--open {
		pointer-events: all;
		-webkit-animation: fadeInOverlay 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation: fadeInOverlay 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;	
	}
}

@-webkit-keyframes fadeInOverlay {
	0% {background-color: transparent;}
	50% {background-color: transparent;}
	100% {background-color: rgba(#BFBFBF, 0.6);}
}
@keyframes fadeInOverlay {
	0% {background-color: transparent;}
	50% {background-color: transparent;}
	100% {background-color: rgba(#BFBFBF, 0.6);}
}