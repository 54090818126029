/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.CircularProgress {
	svg circle {
		transition: stroke-dashoffset 0.5s ease-in-out;
		transform: rotate(-90deg);
		transform-origin: 50% 50%;
	}
	&--landscape {
		font-size: vw-calc(18);
	}
}

@media (max-width:360px)  {
	.CircularProgress svg circle {
		stroke-width: 5px;
	}
}