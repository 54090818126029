/* Used for font sizes in landscape mode */
$reference-width: 1920;
@function vw-calc($size) {
	/* Usually, 22.5*em = width of app wrap */
	$vw-size: ($size / $reference-width) * 22.5;
	@return #{$vw-size}em;
}

@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/variables';
.Quiz {
	color: $text-dark;
	height: 100%;
	background-image: url('../../assets/images/background-stairs.jpg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	&--landscape {
		background-image: url('../../assets/images/background-landscape.jpg');
		background-size: cover;
		padding-top: vw-calc(275);
		.Quiz-header {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: vw-calc(170);
			background-color: rgba(white, 0.5);
			text-align: left;
			z-index: 2;
			@include box-shadow-custom(0, vw-calc(3), vw-calc(6), 0, rgba(#000, .16)); 
		}
		.Quiz-headerAvatarInfo {
			text-align: left;
			display: inline-block;
			vertical-align: top;
			width: vw-calc(610);
			height: vw-calc(170);
			background-image: linear-gradient(#A4D0F1, #BADFFA);
			padding: vw-calc(20) vw-calc(20) vw-calc(20) vw-calc(225);
		}
		.Quiz-headerAvatarInfoText {
			font-size: vw-calc(20);
			padding-top: 1.25em;
			span {
				display: inline-block;
				vertical-align: top;
				font-size: 1.5em;
				font-weight: bold;
			}
		}
	
		.Quiz-headerAvatarStats {
			display: inline-block;
			vertical-align: top;
			width: vw-calc(655);
			height: vw-calc(170);
		}
		.Quiz-buttons {
			padding: vw-calc(50) vw-calc(550);
		}
	}
}

.Quiz-buttons {
	display: flex;
	justify-content: space-between;
	padding: 0 1em;
}
